import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import { Navbar, Nav, NavDropdown, Container, Row, Col } from "react-bootstrap";
import { Theme } from "../../../theme";
import { NavigationItem } from "../../../types/navigation";
import Logo from "../../../components/Logo";
import useLanguage from "../../../hooks/useLanguage";
import _ from 'lodash';

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    fontFamily: 'Playfair Display, serif',
  },
  logo: {
    maxWidth: 140,
  },
  navLink: {
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
    backgroundColor: theme.palette.background.default,
    color: `${theme.palette.text.primary} !important`,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.background.default} !important`,
    },
    "@media (max-width: 1200px)": {
      paddingTop: "0.9rem",
      paddingBottom: "0.9rem",
    },
  },
  activeLink: {
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.background.default} !important`,
    "@media (max-width: 1200px)": {
      paddingTop: "0.9rem",
      paddingBottom: "0.9rem",
    },
  },
  hidden: {
    display: 'none'
  },
  language: {
    cursor: "pointer",
    paddingTop: "25px",
    paddingBottom: "25px",
    paddingLeft: "20px !important",
    paddingRight: "20px !important",
    color: `${theme.palette.text.primary} !important`,
    "&:hover": {
      color: `${theme.palette.primary.main} !important`,
    },
    "@media (max-width: 1200px)": {
      paddingTop: "0.9rem",
      paddingBottom: "0.9rem",
    },
  }
}));

interface HeaderProps {
  generalSettings: any,
  mainNavigation: any
}

const Header: FC<HeaderProps> = ({ mainNavigation, generalSettings }) => {
  const url = new URL(window.location.href);
  const classes = useStyles();
  const languages = require('./Header.json');
  const { lang, setLang } = useLanguage();

  const setLanguage = () => {
    if (lang === 'en') {
      setLang('zh-Hant');
    } else {
      setLang('en');
    }
  }


  const NavItem = (props: any) => {
    if (props.item.items.length > 0) {
      return (
        <NavDropdown className={props.item.menuAttached ? '' : classes.hidden} title={props.item.title} id="main-navigation">
          {props.items.map((item: NavigationItem) => (
            <NavDropdown.Item key={item.id} href={item.path}>
              {item.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    } else {
      return (
        <Link className={props.item.menuAttached ? '' : classes.hidden} to={props.item.path} style={{ textDecoration: "none" }}>
          <Nav.Item
            className={
              url.pathname === props.item.path
                ? classes.activeLink
                : classes.navLink
            }
          >
            {props.item.title}
          </Nav.Item>
        </Link>
      );
    }
  };
  return (
    <Container fluid={true}>
      {generalSettings && <Row>
        <Col
          md={{ span: 12 }}
          lg={{ span: 10, offset: 1 }}
          sm={{ span: 12 }}
        >
          <Navbar expand="xl" className={classes.root}>
            <Navbar.Brand href="/">
              {!generalSettings.logo && generalSettings.sitename && <>{generalSettings.sitename}</>}
              {generalSettings.logo && (
                <Logo
                  className={classes.logo}
                  color="white"
                  url={generalSettings.logo?.url}
                />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                {mainNavigation.items &&
                  mainNavigation.items.map((item: any) => {
                    const translatedItem = _.clone(item);
                    const path = translatedItem.path.replace(/\//g, '').toLowerCase();
                    translatedItem.title = languages[lang!][path];
                    return <NavItem key={item.id} item={translatedItem} />;
                  })}
                <span className={classes.language} onClick={setLanguage}>{lang === 'en' ? '中文' : 'ENGLISH'}</span>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>}
      {!generalSettings && <p>Please start configuring your CMS in admin console</p>}
    </Container>
  );
};

export default Header;
