import React, { FC, useState, useEffect, useCallback } from 'react';
import { Modal, Form, Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import Button from '../../common/Button';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';
import { FormControlElement } from '../../navigation/searches/SearchBar';

const useStyles = makeStyles<Theme, ProductContactModalProps>((theme) => ({
  header: (props) => ({}),
  body: (props) => ({}),
  title: (props) => ({
    color: theme.palette.primary.main,
  }),
  form: (props) => ({}),
  row:(props) => ({
    marginBottom: '2rem',
  }),
  desc: (props) => ({}),
  need: (props) => ({
    color: 'red',
  }),
  text: (props) => ({}),
  input: (props) => ({}),
  message: (props) => ({}),
  message_notice: (props) => ({
    fontSize: props.lang! === 'en' ? '0.7rem' : '1rem',
  }),
  select: (props) => ({}),
  button: (props) => ({}),
}));

export interface ContactInformation {
  name: string;
  company: string;
  nature_of_business: string[];
  title_or_dept: string;
  email: string;
  phone: string;
  message: string;
}

export interface ProductContactModalProps {
  lang?: SupportedLanguages;
  show?: boolean;
  onDownloadClick?: (event: React.MouseEvent<HTMLButtonElement>, data: ContactInformation) => void;
  onCloseClick?: () => void;
}

const ProductContactModal: FC<ProductContactModalProps> = (props): JSX.Element => {
  const classes = useStyles(props);
  const languages = require('./ProductContactModal.json');

  const initStates: ContactInformation = {
    name: '',
    company: '',
    nature_of_business: [
      '',
      'Hotel',
      'Restaurant',
      'Club',
      'Bakery & Confectionery',
      'Wholesaler',
      'Retailer',
      'Private',
      'Others',
    ],
    title_or_dept: '',
    email: '',
    phone: '',
    message: '',
  };
  const [states, setStates] = useState<ContactInformation>(initStates);

  const handleStateChange = useCallback((event: React.ChangeEvent<FormControlElement>): void => {
    const tagetKey = event.target.name;
    let newStates: ContactInformation = _.clone(states);

    Object.entries(states).forEach(([key, state]) => {
      if (!Array.isArray(state)) {
        newStates[key] = key === tagetKey ? event.target.value : state;
      }
    });

    setStates(newStates);
  }, [states])

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const data = initStates;
    
    Object.entries(states).forEach(([key, state]) => {
      if (Array.isArray(state)) {
        const stateElement = document.getElementById(key)! as HTMLSelectElement;
        data[key] = [stateElement.value];
      } else {
        data[key] = state;
      }
    });

    props.onDownloadClick?.(event, data);
  }

  const renderStateControls = useCallback((): JSX.Element[] => (
    Object.entries(states).map(([key, state], index) => {
      let formControlElement;
      if (Array.isArray(state)) {
        formControlElement = <Form.Control as='select' className={classes.select} onChange={handleStateChange} name={key} key={key} id={key}>
          {
            state.map((value: string) => (
              value === '' ?
              <option key={'choose'} value=''>Choose Me</option> :
              <option key={value} value={value}>{value}</option>
            ))
          }
        </Form.Control>
      } else {
        switch (key) {
          case 'email':
            formControlElement = <Form.Control as='input' type={key} className={classes.input} value={state} onChange={handleStateChange} name={key} key={key} />
            break;
          case 'phone':
            formControlElement = <Form.Control as='input' type='tel' className={classes.input} value={state} onChange={handleStateChange} name={key} key={key} />
            break;
          case 'message':
            formControlElement = <Form.Control as='textarea' type='input' className={classes.message} value={state} onChange={handleStateChange} name={key} key={key} style={{ height: '12rem' }} />
            break;
          default:
            formControlElement = <Form.Control as='input' type='input' className={classes.input} value={state} onChange={handleStateChange} name={key} key={key} />
        }
      }

      return (
        <Row key={key} className={classes.row}>
          <Col key={'l' + index}
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 3, offset: 0 }}
          >
            <Form.Label key={index} className={classes.text} htmlFor={key}>
              {
                key !== 'message' && <Form.Label key={index} className={classes.need} htmlFor={key}>*</Form.Label>
              }
              
              {languages[props.lang!][key]}
            </Form.Label>
          </Col>
          <Col key={'r' + index}
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 9, offset: 0 }}
          >
            {
              key === 'message' &&
              <Form.Label key={'message_notice' + index} className={classes.message_notice} htmlFor={key}>
                {languages[props.lang!]['message_notice']}
              </Form.Label>
            }
            {formControlElement}
          </Col>
        </Row>
      )
    })
  ), [states, classes.row, classes.text, classes.need, classes.select, classes.input, classes.message, classes.message_notice, languages, props.lang, handleStateChange]);

  useEffect(() => {
    renderStateControls();
  }, [renderStateControls]);

  return (
    <Modal size="lg" show={props.show} onHide={props.onCloseClick}>
      <Modal.Header className={classes.header} closeButton>
        <Modal.Title><span className={classes.title}>{languages[props.lang!].title}</span></Modal.Title>
      </Modal.Header>

      <Modal.Body className={classes.body}>
        <Container fluid>
          <Form className={classes.form}>
            {
              renderStateControls()
            }
          </Form>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button className={classes.button} onClick={props.onCloseClick}>{languages[props.lang!].cancel}</Button>
        <Button className={classes.button} onClick={handleDownloadClick}>{languages[props.lang!].download}</Button>
      </Modal.Footer>
    </Modal>
  )
}

ProductContactModal.defaultProps = {
  lang: 'en',
  show: true,
}

export default ProductContactModal;
