import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { isEmpty, template } from 'lodash';
import marked from 'marked';

import { Theme } from '../../theme';
import { postContact } from '../../graphql/contacts';
import GoogleMap, { GoogleMapProps, GoogleMapMarker } from '../GoogleMap';
import ImageButton from '../common/ImageButton';

const useStyles = makeStyles<Theme, ContactMapProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    marginBottom: '1rem',
  },
  containerRow: {},
  containerCol: {},
  map: {
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
    width: '100%',
    height: '100%',
  },
  form: {},
  formRow: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
    marginBottom: '1rem',
    justifyContent: 'center',
  },
  formCol: {},
  text: {},
  need: {
    color: 'red',
  },
  to: {
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  name: {},
  company: {},
  email: {},
  contactNumber: {},
  subject: {},
  message: {},
  submit: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export interface ContactMapProps {
  id: string;
  text_to?: string;
  text_name?: string;
  text_company?: string;
  text_email?: string;
  text_contact_number?: string;
  text_subject?: string;
  text_message?: string;
  text_button_send?: string;
  google_map: GoogleMapProps;
  contact_markers?: ContactMapMarker[];
}

export interface ContactMapMarker extends GoogleMapMarker {
  content?: string,
}

function isValidEmail(value: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value);
}

const ContactMap: FC<ContactMapProps> = (props) => {
  const classes = useStyles(props);

  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sendContactMe] = useMutation(postContact);

  const {
    id, api_key, latitude, longitude, zoom,
    ...rest
  } = props.google_map;

  const markers: any[] = [];
  const infowindows: any[] = [];
  const handleGoogleMapLoaded = (map: any, maps: any) => {
    props.contact_markers?.forEach((marker) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: marker.latitude,
            lng: marker.longitude,
          },
          icon: marker.icon ? marker.icon!.url : undefined,
          map,
        })
      );

      const isMarkerContentEmpty = isEmpty(marker.content);
      const compiled = template(marker.content!);

      const { content, ...allowedVariables } = marker; // exclude content avoid looping

      infowindows.push(
        new maps.InfoWindow({
          content: !isMarkerContentEmpty
          ?
            marked(compiled({...allowedVariables}))
          :
            ''
          ,
        })
      );
    });

    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    switch(target.name) {
      case 'name':
        setName(value);
        break;
      case 'company':
        setCompany(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'contactNumber':
        setContactNumber(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'message':
        setMessage(value);
        break;
    }
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (name.length > 0 &&
        company.length > 0 &&
        email.length > 0 &&
        contactNumber.length > 0 &&
        subject.length > 0 &&
        message.length > 0 &&
        isValidEmail(email))
    {
      sendContactMe({ variables: { name, company, email, contactNumber, subject, message }});

      setSubject('');
      setMessage('');
    } else {
      // TODO: improve alert
      if (isEmpty(name)) {
        alert('Please fill in name');
      } else if (isEmpty(company)) {
        alert('Please fill in company');
      } else if (isEmpty(email)) {
        alert('Please fill in email');
      } else if (!isValidEmail(email)) {
        alert('Please fill in valid email');
      } else if (isEmpty(contactNumber)) {
        alert('Please fill in contact number');
      } else if (isEmpty(subject)) {
        alert('Please fill in subject');
      } else if (isEmpty(message)) {
        alert('Please fill in message');
      }
    }
  }

  return (
    <Container className={classes.container}>
      <Row className={classes.containerRow}>
        <Col
          className={classes.containerCol}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
        >
          <GoogleMap
            className={classes.map}
            id={id}
            api_key={api_key}
            latitude={latitude}
            longitude={longitude}
            zoom={zoom}
            onGoogleMapLoaded={handleGoogleMapLoaded}
            {...rest}
          />
        </Col>
        <Col
          className={classes.containerCol}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: 6, offset: 0 }}
        >
          <Form className={classes.form}>
            <Row className={classes.formRow}>
              <h5 className={classes.to}>
                {props.text_to}
              </h5>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='name'>
                  <Form.Label className={classes.need} htmlFor='name'>{'*'}</Form.Label>
                  {props.text_name}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control className={classes.name} value={name} onChange={handleChange} name='name' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='company'>
                  <Form.Label className={classes.need} htmlFor='company'>{'*'}</Form.Label>
                  {props.text_company}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control className={classes.company} value={company} onChange={handleChange} name='company' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='email'>
                  <Form.Label className={classes.need} htmlFor='email'>{'*'}</Form.Label>
                  {props.text_email}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control className={classes.email} value={email} onChange={handleChange} name='email' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='contactNumber'>
                  <Form.Label className={classes.need} htmlFor='contactNumber'>{'*'}</Form.Label>
                  {props.text_contact_number}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control className={classes.contactNumber} value={contactNumber} onChange={handleChange} name='contactNumber' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='subject'>
                  <Form.Label className={classes.need} htmlFor='subject'>{'*'}</Form.Label>
                  {props.text_subject}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control className={classes.subject} value={subject} onChange={handleChange} name='subject' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 0 }}
              >
                <Form.Label className={classes.text} htmlFor='message'>
                  <Form.Label className={classes.need} htmlFor='message'>{'*'}</Form.Label>
                  {props.text_message}
                </Form.Label>
              </Col>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 0 }}
              >
                <Form.Control as='textarea' className={classes.message} value={message} onChange={handleChange} name='message' />
              </Col>
            </Row>
            <Row className={classes.formRow}>
              <Col
                className={classes.formCol}
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 6, offset: 1 }}
              >
                <ImageButton
                  className={classes.submit}
                  type='button'
                  text={props.text_button_send!}
                  image_position={'none'}
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

ContactMap.defaultProps = {
  text_to: 'To Angliss Hong Kong Food Service Limited',
  text_name: 'Name',
  text_company: 'Company',
  text_email: 'Email',
  text_contact_number: 'Contact Number',
  text_subject: 'Subject',
  text_message: 'Your Message',
  text_button_send: 'Send Message',
}

export default ContactMap;
