/**
 * this document clone from
 * CMS/plugins/wysiwyg/admin/src/components/QuillEditor/index.js
 * register font types part
 */

// register font types
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, '-');
}

// add supported fonts
const fonts = ['Sans Serif', 'Serif', 'Monospace', 'Playfair Display', 'Caladea', 'Amiri']; 

// add related font styles
let fontStyles = '';
fonts.forEach(function(font) {
  const fontName = getFontName(font);
  fontStyles += '.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' + fontName + ']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' + fontName + ']::before {' +
      'content: "' + font + '";' +
      'font-family: "' + font + '", sans-serif;' +
      '}' +
      '.ql-font-' + fontName + '{' +
      ' font-family: "' + font + '", sans-serif;' +
      '}';
});
const node = document.createElement('style');
node.innerHTML = fontStyles;
document.head.appendChild(node);
