import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "../../components/Page";
import { Theme } from "../../theme";
import axiosInstance from "../../utils/axios";
import { API_URL } from "../../constants";
import Spinner from '../../components/common/Spinner';
import { Container, Row, Col } from "react-bootstrap";
import ImageWithFrame from '../../components/common/ImageWithFrame';
import Carrousel, { ProductCarouselProps } from "../../components/angliss/product/Carousel";
import ArrowBreadcumbs from "../../components/navigation/breadcumbs/ArrowBreadcumbs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import useLanguage from "../../hooks/useLanguage";
import languages from './BrandDetail.json';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    marginTop: 40,
    marginBottom: 40,
  },
  bread: {
    '& .MuiBreadcrumbs-separator': {
      margin: 0,
    }
  },
  row: {
    flexWrap: 'wrap-reverse',
  },
  title: {
    fontFamily: 'Playfair Display',
    fontSize: 60,
  },
  image: {
    backgroundSize: 'contain !important',
  },
  carrousel: {
    marginTop: 40,
    '& .slick-slide': {
      lineHeight: 0,
    }
  },
  carrouselImg: {
    height: '350px !important',
    width: '350px !important',
    backgroundSize: 'auto 350px !important',
    margin: '0 auto',
    '@media (max-width: 1200px)': {
      height: '250px !important',
      width: '250px !important',
      backgroundSize: 'auto 250px !important',
    }
  },
  nextArrow: {
    right: '-8px',
    width: '2.5rem',
    height: '2.5rem',
    zIndex: 100,
  },
  prevArrow: {
    left: '-10px',
    width: '2.5rem',
    height: '2.5rem',
    zIndex: 100,
  },
  backRow: {
    marginTop: 40,
    textAlign: 'center'
  },
  backBtn: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    color: 'white',
    borderRadius: 0,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText
    }
  },
  btnText: {
    marginLeft: 30
  },
}));

function PrevArrow(props: any) {
  const { className, clazz, onClick } = props;
  return (
    <img onClick={onClick} className={className + ' ' + clazz} src="/assets/images/arrow_prev.png" alt="arrow" />
  );
}

function NextArrow(props: any) {
  const { className, clazz, onClick } = props;
  return (
    <img onClick={onClick} className={className + ' ' + clazz} src="/assets/images/arrow_next.png" alt="arrow" />
  );
}

interface BrandDetailProps { }
const BrandDetail: FC<BrandDetailProps> = () => {
  const classes = useStyles();
  const url = new URL(window.location.href);
  const [postObject, setPostObject] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { lang = 'en' } = useLanguage();

  const pathParams: any = url.pathname.split('/');
  const code: string = decodeURIComponent(pathParams[2]);
  let backPathParams: any = [];
  if (pathParams) {
    backPathParams = [...pathParams];
    backPathParams.pop();
  }

  const getPostData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`${API_URL}/brands?_locale=${lang}&code_eq=${code}`);
      setPostObject(data[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [code, lang]);

  useEffect(() => {
    getPostData();
  }, [getPostData, lang]);

  const slides = postObject?.photo?.map((p: any) => (
    {
      className: classes.carrouselImg,
      image_url: p.url,
      // frame_width: 'auto',
      // frame_height: '15rem',
      img_width: p.width,
      img_height: p.height,
    }
  )) || [];
  const carrouselProps: ProductCarouselProps = {
    className: classes.carrousel,
    slide_per_view: slides?.length >= 3 ? 3 : slides?.length,
    slide_to_scroll: 1,
    slides,
    pause_on_hover: true,
    indicators: true,
    infinite: true,
    prevArrow: <PrevArrow clazz={classes.prevArrow} />,
    nextArrow: <NextArrow clazz={classes.nextArrow} />,
  };

  return (
    <Page className={classes.root} title="Angliss" font={postObject?.font}>
      {
        loading ? <Spinner /> :
          <Container>
            <ArrowBreadcumbs className={classes.bread} links={['/brands', code]} names={[languages[lang].title, code]} />
            <div className={classes.title}>{postObject?.name}</div>
            <Row className={classes.row}>
              <Col sm={{ span: 9, offset: 0 }} className="ql-editor">
                <div
                  className={`text-left`}
                  dangerouslySetInnerHTML={{
                    __html: postObject?.desc
                  }}
                ></div>
              </Col>
              <Col sm={{ span: 3 }}>
                <ImageWithFrame
                  className={classes.image}
                  image_url={postObject?.cover?.url}
                  frame_width={'9rem'}
                  frame_height={'9rem'} />
              </Col>
            </Row>
            <Carrousel {...carrouselProps} />
            <Row className={classes.backRow}>
              <Col>
                <Link className={classes.backBtn} to={backPathParams.join('/')}><FontAwesomeIcon
                  icon={faLongArrowAltLeft} /><span className={classes.btnText}>{languages[lang].back}</span></Link>
              </Col>
            </Row>
          </Container>
      }
    </Page >
  )
}
export default BrandDetail;
