import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import { Theme } from '../theme';

export interface IFrameProps {
  id: number,
  title: string,
  url: string,
  container_style?: string,
  iframe_style?: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  container: {},
  row: {},
  col: {},
  iframe: {
    width: '100%',
    height: '100vh',
  }
}));

/**
 * @deprecated
 */
const IFrame: FC<IFrameProps> = ({ title, url, container_style, iframe_style }) => {
  const classes = useStyles();

  const containerClasses = container_style ? container_style : classes.container;
  const iframeClasses = iframe_style ? iframe_style : classes.container;

  return (
    <section className={classes.root}>
      <Container fluid={true} className={containerClasses}>
        <Row className={classes.row}>
          <Col className={classes.col}>
            <iframe className={iframeClasses} title={title} src={url}></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default IFrame;
