import { gql } from '@apollo/client';
export const getGeneralSettings = (locale = 'en') => gql`
    query {
        general(locale: "${locale}") {
            logo {
                url
            }
            white_logo {
                url
            }
            sitename
            description
            address
            phone
            email
            contact_us_text
            terms_of_use_text
            terms_of_use_link
            privacy_policy_text
            privacy_policy_link
            bidcorp
        }
    }
`