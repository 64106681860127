import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import Issuu, { IssuuProps } from './Issuu';

const useStyles = makeStyles<Theme, GalleryProps>((theme) => ({
  container: (props) => ({}),
  row: (props) => ({}),
  col: (props) => ({}),
}));

export interface GalleryProps {
  id: number;
  issuus?: IssuuProps[];
}

/**
 * @deprecated using container posts display gallery instead
 */
const Gallery: FC<GalleryProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  return (
    <Container className={classes.container} fluid={true}>
      <Row className={classes.row}>
        {
          props.issuus &&
          props.issuus.map(
            (issuu, index) => {
              return (
                <Col key={index}
                  className={classes.col}
                  xs={{span: 12, offset: 0}}
                  sm={{span: 12, offset: 0}}
                  md={{span: 4, offset: 0}}
                >
                  <Issuu key={index}
                    iframe_title={issuu.iframe_title}
                    iframe_url={issuu.iframe_url}
                  />
                </Col>
              )
            }
          )
        }
      </Row>
    </Container>
  )
}

Gallery.defaultProps = {

};

export default Gallery;
