import React, { FC, useLayoutEffect, useState } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';
import { UploadFile } from '../../../types/uploadFile';
import ImageWithFrameAndText, { ImageWithFrameAndTextProps } from '../../common/ImageWithFrameAndText';

const useStyles = makeStyles<Theme, HighlightProps>((theme) => ({
  slider: (props) => ({}),
  slide: (props) => ({
    cursor: 'pointer',
  }),
  image: (props) => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
  }),
}));

export interface HighlightSlide {
  id?: string;
  image?: UploadFile;
  sequence?: number;
}

export interface HighlightProps {
  className?: string;
  slides?: ImageWithFrameAndTextProps[];
  slide_per_view?: number;
  slide_to_scroll?: number;
  auto_play?: boolean;
  auto_play_speed?: number;
  pause_on_hover?: boolean;
  infinite?: boolean;
  indicators?: boolean;
  nextArrow?: JSX.Element;
  prevArrow?: JSX.Element;
  onSlideClick?: (event: React.MouseEvent<HTMLDivElement>, slide: ImageWithFrameAndTextProps) => void;
}

const Highlight: FC<HighlightProps> = (props) => {
  const classes = useStyles(props);

  const [slides, setSlides] = useState<ImageWithFrameAndTextProps[]>([]);

  const handleSlideClick = (event: React.MouseEvent<HTMLDivElement>, slide: ImageWithFrameAndTextProps) => {
    props.onSlideClick?.(event, slide);
  }

  useLayoutEffect(() => {
    setSlides(props.slides!);
  }, [props.slides]);

  const settings = {
    slidesToShow: props.slide_per_view,
    slidesToScroll: props.slide_per_view,
    autoplay: props.auto_play,
    autoplaySpeed: props.auto_play_speed,
    pauseOnHover: props.pause_on_hover,
    infinite: props.infinite && props.slides!.length > props.slide_per_view!,
    dots: props.indicators,
    // nextArrow: props?.nextArrow,
    // prevArrow: props?.prevArrow,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
          slidesToScroll: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
          autoplay: props.auto_play,
          autoplaySpeed: props.auto_play_speed,
          pauseOnHover: props.pause_on_hover,
          infinite: props.infinite && props.slides!.length > props.slide_per_view! && props.slides!.length > 2,
          dots: props.indicators,
          // nextArrow: props?.nextArrow,
          // prevArrow: props?.prevArrow,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: props.auto_play,
          autoplaySpeed: props.auto_play_speed,
          pauseOnHover: props.pause_on_hover,
          infinite: props.infinite && props.slides!.length > props.slide_per_view! && props.slides!.length > 1,
          dots: props.indicators,
          // nextArrow: props?.nextArrow,
          // prevArrow: props?.prevArrow,
        },
      },
    ],
  };

  return (
    <>
      {
        slides!.length === 0 ?
          <ImageWithFrameAndText className={classes.slider + (props.className ? ' ' + props.className : '')} />
        :
          <Slider className={classes.slider + (props.className ? ' slider ' + props.className : '')} {...settings}>
            {
              slides.map((slide: ImageWithFrameAndTextProps, i: number) => (
                <div key={i} className={classes.slide}>
                  <ImageWithFrameAndText className={classes.image} {...slide} onClick={event => handleSlideClick(event, slide)} />
                </div>
              ))
            }
          </Slider>
      }
    </>
  )
}

Highlight.defaultProps = {
  slides: [],
  slide_per_view: 3,
  auto_play: true,
  auto_play_speed: 4000,
  pause_on_hover: true,
  infinite: true,
  indicators: false,
}

export default Highlight;
