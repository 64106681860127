import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { GridSelectionModel } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import ProductPdfPreviewTable from './ProductPdfPreviewTable';
import { Product as ProductDataGridProduct } from './ProductDataGrid';
import Button from '../../common/Button';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductPdfPrviewModalProps>((theme) => ({
  header: (props) => ({}),
  body: (props) => ({}),
  pdficon: (props) => ({
    color: '#f40f02',
  }),
  title: (props) => ({
    color: theme.palette.primary.main,
  }),
  button: (props) => ({}), 
}));

export interface ProductPdfPrviewModalProps {
  lang?: SupportedLanguages;
  show?: boolean;
  products?: ProductDataGridProduct[];
  selected?: GridSelectionModel;
  onDownloadClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseClick?: () => void;
}

const ProductPdfPrviewModal: FC<ProductPdfPrviewModalProps> = (props): JSX.Element  => {
  const classes = useStyles(props);
  const languages = require('./ProductPdfPreviewModal.json');

  const selectedProducts = props.products!.filter(product => props.selected!.indexOf(product.id) > -1);
  return (
    <Modal size="lg" show={props.show} onHide={props.onCloseClick}>
      <Modal.Header className={classes.header} closeButton>
        <Modal.Title><FontAwesomeIcon className={classes.pdficon} icon={faFilePdf} />{' '}<span className={classes.title}>{languages[props.lang!].title}</span></Modal.Title>
      </Modal.Header>

      <Modal.Body className={classes.body}>
        <ProductPdfPreviewTable lang={props.lang!} products={selectedProducts} />
      </Modal.Body>

      <Modal.Footer>
        <Button className={classes.button} onClick={props.onCloseClick}>{languages[props.lang!].cancel}</Button>
        <Button className={classes.button} onClick={props.onDownloadClick}>{languages[props.lang!].download}</Button>
      </Modal.Footer>
    </Modal>
  )
}

ProductPdfPrviewModal.defaultProps = {
  lang: 'en',
  show: true,
  products: [],
  selected: [],
}

export default ProductPdfPrviewModal;
