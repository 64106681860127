import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}
const Logo: FC<LogoProps> = (props) => {
  if (props.color === "white") {
    return (
      <img
        alt="Logo"
        src={props.url}
        {...props}
      />
    );
  } else {
    return (
      <img
        alt="Logo"
        src={props.url}
        {...props}
      />
    );
  }
}

export default Logo;
