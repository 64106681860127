import { gql } from "@apollo/client";
export const postContact = gql
(
  `
    mutation SendContactMe($name: String!, $company: String!, $email: String!, $contactNumber: String!, $subject: String!, $message: String!) {
      createContact(
        input:{
          data: {
            name: $name,
            company: $company,
            email: $email,
            contact_number: $contactNumber,
            subject: $subject,
            message: $message
          }
        }) {
        contact {
          id
          name
          company
          email
          contact_number
          subject
          message
          created_at
          updated_at
        }
      }
    }
  `
);