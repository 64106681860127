import {
  createContext,
  useEffect,
  useState
} from 'react';
import type { FC, ReactNode } from 'react';
import { SupportedLanguages } from '../types/SupportedLanguages';

export interface LanguageContextValue {
  lang: SupportedLanguages;
  setLang: (update: SupportedLanguages) => void;
}

interface LanguageProviderProps {
  lang?: SupportedLanguages;
  children?: ReactNode;
}

const defaultLanguage ='en';

export const restoreLanguage = ():any => {
  let language = defaultLanguage;

  try {
    const storedData = window.localStorage.getItem('language') || defaultLanguage;

    if (storedData) {
      language = storedData;
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return language;
};

export const storeLanguage = (lang: SupportedLanguages): void => {
  window.localStorage.setItem('language', lang);
};

const LanguageContext = createContext<LanguageContextValue>({
  lang: defaultLanguage,
  setLang: () => { }
});

export const LanguageProvider: FC<LanguageProviderProps> = ({ lang, children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguages>(lang || defaultLanguage);

  const handleSaveLanguage = (update: SupportedLanguages): void => {
    setCurrentLanguage(update);
    storeLanguage(update);
  };

  useEffect(() => {
    const restoredLang = restoreLanguage();

    if (restoredLang) {
      setCurrentLanguage(restoredLang);
    }
  }, []);

  // useEffect(() => {
  //   document.dir = currentLanguage.lang || 'en';
  // }, [currentSettings]);

  return (
    <LanguageContext.Provider
      value={{
        lang: currentLanguage,
        setLang: handleSaveLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const SettingsConsumer = LanguageContext.Consumer;

export default LanguageContext;
