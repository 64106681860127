import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import ImageWithFrame, { ImageWithFrameProps } from './ImageWithFrame';
import Text from './Text';

const useStyles = makeStyles<Theme, ImageWithFrameAndTextProps>((theme) => ({
  root: (props) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  image: (props) => ({}),
  text: (props) => ({
    textAlign: props.textAlign!,
  }),
}));

export interface ImageWithFrameAndTextProps extends ImageWithFrameProps {
  text?: string;
  textAlign?: 'left' | 'center' | 'right';
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

const ImageWithFrameAndText: FC<ImageWithFrameAndTextProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  return (
    <div className={classes.root + (props.className ? ' ' + props.className : '')} onClick={props.onClick}>
      <ImageWithFrame
        className={classes.image}
        frame_width={props.frame_width}
        frame_height={props.frame_height}
        image_url={props.image_url}
        image_width={props.image_width}
        image_height={props.image_height}
      />
      {
        props.text && <Text className={classes.text} html={props.text} />
      }
    </div>
  );
}

ImageWithFrameAndText.defaultProps = {
  frame_width: '100%',
  frame_height: '12rem',
  textAlign: 'left',
}

export default ImageWithFrameAndText;
