import { FC } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { makeStyles } from "@material-ui/core";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import { Theme, colors } from "../../../theme";
import Logo from "../../../components/Logo";
import Text from '../../../components/common/Text';
import useLanguage from "../../../hooks/useLanguage";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        color: '#898989',
        fontSize: 14,
        '& a': {
            color: theme.palette.primary.main,
            '&:hover': {
                color: '#0056b3',
            }
        }
    },
    footerNav: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    copyRight: {
        backgroundColor: colors.grey[900],
        paddingTop: 10,
        paddingBottom: 10
    },
    linkSeparator: {
        marginLeft: 10,
        marginRight: 10
    },
    logo: {
        maxWidth: 140,
        marginBottom: 20
    },
    aboutUs: {
        "&>*": {
            marginBottom: 20
        },
        "&> h5": {
            marginBottom: 30,
            color: theme.palette.common.white
        }
    },
    description: {
        '& img:hover': {
            backgroundColor: 'white',
            borderRadius: '50%',
            cursor: 'pointer',
        },
    },
}));

interface FooterProps {
    generalSettings: any,
    mainNavigation: any
}

const Footer: FC<FooterProps> = ({ mainNavigation, generalSettings }) => {
    const classes = useStyles();
    const languages = require('./Header.json');
     
    const { lang } = useLanguage();
    const url = new URL(window.location.href);
    const isHomePage = url.pathname === '/';

    return (
        <div className={classes.root}>
            {generalSettings && (
                <Container fluid={true} className="border-0 rounded-0">
                    {!isHomePage && <Row className={classes.footerNav}>
                        <Col
                            md={{ span: 6 }}
                            lg={{ span: 3, offset: 1 }}
                            sm={{ span: 6 }}
                            xs={{ span: 12 }}
                        >
                            <Navbar.Brand href="/">
                                {!generalSettings.white_logo && <>{generalSettings.sitename}</>}
                                {generalSettings.white_logo && (
                                    <Logo
                                        className={classes.logo}
                                        color="white"
                                        url={generalSettings.white_logo.url}
                                    />
                                )}
                            </Navbar.Brand>
                            <p>
                                <Text className={classes.description} html={generalSettings.description} />
                            </p>
                        </Col>
                        <Col
                            md={{ span: 6 }}
                            lg={{ span: 3, offset: 1 }}
                            sm={{ span: 6 }}
                            xs={{ span: 12 }}
                            className={classes.aboutUs}
                        >
                            <h5>
                                {generalSettings.contact_us_text}
                            </h5>
                            <p>
                                {generalSettings.address}
                            </p>
                            <p>
                                {generalSettings.phone}
                            </p>
                            <p>
                                {generalSettings.email}
                            </p>
                        </Col>
                        <Col
                            md={{ span: 6 }}
                            lg={{ span: 3 }}
                            sm={{ span: 6 }}
                            xs={{ span: 12 }}
                            className={classes.aboutUs}
                        >
                            <Row>
                                {mainNavigation.items.map((item: any, index: number) => {
                                    return item.menuAttached ? <Col
                                        key={index}
                                        style={{ marginTop: 20 }}
                                        lg={{ span: 6 }}
                                        xs={{ span: 12 }}>
                                        <Link to={item.path ? item.path : ''}
                                            style={{ textDecoration: "none" }}>
                                            {languages[lang][item.path.substring(1).toLowerCase()]}
                                        </Link></Col> : ''
                                })}
                            </Row>
                        </Col>
                    </Row>}
                    <Row className={classes.copyRight}>
                        <Col
                            lg={{ offset: 1, span: 5 }}
                            xs={{ span: 12 }}>
                            <span>Copyright @{moment().format('YYYY')} Angliss Hong Kong Food Service Ltd. All Rights Reserved. Development by <a href="https://synque.io" target="_blank" rel="noreferrer">Synque</a></span>
                        </Col>
                        <Col className="text-left text-lg-right"
                            lg={{ span: 5 }}
                            xs={{ span: 12 }}>
                            <span>
                                <Link to={generalSettings.terms_of_use_link} rel="noreferrer">{generalSettings.terms_of_use_text}</Link>
                                <span className={classes.linkSeparator}>|</span>
                                <Link to={generalSettings.privacy_policy_link} rel="noreferrer">{generalSettings.privacy_policy_text}</Link>
                                <span className={classes.linkSeparator}></span>
                                <a href={generalSettings.bidcorp} target="_blank" rel="noreferrer"><img src='/assets/images/bidcorp-logo.png' alt="bidcorp logo" style={{ height: 20 }} /></a>
                            </span>
                        </Col>
                    </Row>
                </Container>
            )}
        </div>
    );
};

export default Footer;
