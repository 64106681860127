import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';
import { ImageWithFrameAndTextProps } from '../../common/ImageWithFrameAndText';
import HighlightSlideModalCarousel from './HighlightSlideModalCarousel';

const useStyles = makeStyles<Theme, HighlightSlideModalProps>((theme) => ({
  root: (props) => ({}),
}));

export interface HighlightSlideModalProps {
  show?: 'Y' | 'N';
  slides?: ImageWithFrameAndTextProps[];
  slide?: ImageWithFrameAndTextProps;
  onCloseClick?: () => void;
}

const HighlightSlideModal: FC<HighlightSlideModalProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Modal className={classes.root} size="lg" show={props.show === 'Y'} onHide={props.onCloseClick}>
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <HighlightSlideModalCarousel slides={props.slides} slide={props.slide} />
      </Modal.Body>
    </Modal>
  )
}

HighlightSlideModal.defaultProps = {
  show: 'Y',
}

export default HighlightSlideModal;
