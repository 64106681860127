import {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';
import HeaderLayout from './layouts/HeaderLayout';
import SwitchView from './views/cms/AnglissView/SwitchView';
import PageView from './views/cms/AnglissView/PageView';

type Routes = {
    exact?: boolean;
    path: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];


export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<div />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact || false}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
      exact: true,
      path: '/404',
      component: lazy(() => import('./views/errors/NotFoundView'))
    },
    {
        path: '*',
        layout: HeaderLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: PageView
            },

            {
                exact: true,
                path: '/*/:id',
                component: SwitchView
            },
            {
                exact: true,
                path: '/*',
                component: PageView
            },
        ]
    }
];

export default routes;
