import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import { UploadFile } from '../../types/uploadFile';
import Image from '../common/Image';

const useStyles = makeStyles<Theme, ImagesProps>((theme) => ({
  container: (props) => ({}),
  row: (props) => ({}),
  col: (props) => ({
    marginBottom: '2rem',
  }),
}));

export interface ImagesProps {
  id: string;
  images?: UploadFile[];
  images_per_row?: number;
  images_height?: string;
}

const Images: FC<ImagesProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  const imageSpanSize = Math.floor(12 / (props.images_per_row ?? Images.defaultProps?.images_per_row ?? 2));
  return (
    <Container className={classes.container}>
      <Row className={classes.row}>
        {
          props.images &&
          props.images.map(
            (image, index) => {
              return (
                <Col key={index}
                  className={classes.col}
                  xs={{span: 12, offset: 0}}
                  sm={{span: 12, offset: 0}}
                  md={{span: imageSpanSize, offset: 0}}
                >
                  <Image
                    image={image}
                    image_width={'100%'}
                    image_height={props.images_height}
                    image_fluid={false}
                  />
                </Col>
              )
            }
          )
        }
      </Row>
    </Container>
  )
}

Images.defaultProps = {
  images_per_row: 2,
  images_height: '500px',
};

export default Images;
