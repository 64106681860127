import { FC } from 'react';
import { Button, Badge } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductPreviewButtonProps>((theme) => ({
  root: (props) => ({
    alignSelf: 'flex-end',
  }),
  button: (props) => ({
    background: theme.palette.primary.main,
    color: 'white',
    marginBottom: '2rem',
    '&:hover': {
      boxShadow: '0 0 0 .2rem rgba(190, 162, 107, .25)',
      background: 'white',
      color: theme.palette.primary.main,
    },
  }),
  badge: (props) => ({
    marginRight: '1rem',
  }),
}));

export interface ProductPreviewButtonProps {
  lang?: SupportedLanguages;
  selected?: number;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ProductPreviewButton: FC<ProductPreviewButtonProps> = (props): JSX.Element  => {
  const classes = useStyles(props);
  const languages = require('./ProductPreviewButton.json');

  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.onClick?.(event)}>
        <Badge className={classes.badge} badgeContent={props.selected} color='error'>
          <FontAwesomeIcon icon={faFilePdf} />
        </Badge>
        {languages[props.lang!].placeholder}
      </Button>
    </div>
  )
}

ProductPreviewButton.defaultProps = {
  lang: 'en',
  selected: 0,
}

export default ProductPreviewButton;
