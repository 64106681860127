import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid as MaterialDataGrid, GridColDef, GridRowData, GridRowParams, MuiEvent, GridSelectionModel } from '@material-ui/data-grid';
import _ from 'lodash';

import { Theme } from '../../../theme';
import ProductInterface, { Show } from '../../../types/Product';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductsDataGridProps>((theme) => ({
  root: (props) => ({
    width: '100%',
    height: '70vh',
  }),
}));

export class Product implements ProductInterface, GridRowData {
  [key: string]: any;

  constructor(
    public id = 0,
    public prod_no = '',
    public company_cd = '',
    public prod_desc_web = '',
    public spec = '',
    public major_unit = '',
    public minor_unit = '',
    public major_wt = 0,
    public minor_wt = 0,
    public selling_basis = '',
    public minor_per_major = 0,
    public pick_cfm_req = '',
    public cat1 = '',
    public cat2 = '',
    public stor_con = '',
    public brand = '',
    public brand1 = '',
    public country_name = '',
    public country_abb_name = '',
    public meat = '',
    public show: Show = 'N',
  ) {}
}

export class ProductColDef implements GridColDef {
  constructor(
    public field: string,
    public headerName: string = _.startCase(field).replaceAll('_', ' '),
    public width = 150,
    public hide = false,
    public sortable = true,
  ) {}
}

export interface ProductsDataGridProps {
  lang?: SupportedLanguages;
  selected?: GridSelectionModel;
  data?: Product[];
  pageSize?: number;
  onCheckboxChange?: (model: GridSelectionModel) => void;
  onRowClick?: (param: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => void;
}

export const DataGrid: FC<ProductsDataGridProps> = (props): JSX.Element => {
  const classes = useStyles(props);
  const languages = require('./ProductDataGridLang.json');

  const productHeaders = Object.keys(new Product())
    .filter((key) => {
      switch (key) {
        case 'brand1':
        case 'show':
          return false;
        default:
          return true;
      }
    })
    .map((key) => {
      switch (key) {
        case 'id':
          return new ProductColDef(key, languages[props.lang!][key], 80, true);
        case 'prod_no':
          return new ProductColDef(key, languages[props.lang!][key], 150, true);
        case 'company_cd':
          return new ProductColDef(key, languages[props.lang!][key], 150, true);
        case 'prod_desc_web':
          return new ProductColDef(key, languages[props.lang!][key], 500);
        case 'spec':
          return new ProductColDef(key, languages[props.lang!][key], 200);
        case 'major_unit':
          return new ProductColDef(key, languages[props.lang!][key], 150, true);
        case 'minor_unit':
          return new ProductColDef(key, languages[props.lang!][key], 150, true);
        case 'major_wt':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'minor_wt':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'selling_basis':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'minor_per_major':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'pick_cfm_req':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'cat1':
          return new ProductColDef(key, languages[props.lang!][key], 200);
        case 'cat2':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'stor_con':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'brand':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'country_name':
          return new ProductColDef(key, languages[props.lang!][key], 200);
        case 'country_abb_name':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        case 'meat':
          return new ProductColDef(key, languages[props.lang!][key], 200, true);
        default:
          return new ProductColDef(key);
      }
    }).sort((header1, header2) => {
      switch (header1.field) {
        case 'cat1':
          return 1;
      }

      return 0;
    });

  const handleSelectionModelChange = (model: GridSelectionModel) => {
    props.onCheckboxChange?.(model);
  }

  const rows = props.data!.filter((value) => value.show === 'Y');
  const selectionModel = rows.filter((row) => props.selected!.indexOf(row.id) > -1).map((row) => row.id);
  return (
    <div className={classes.root} id='product_data_grid'>
      <MaterialDataGrid
        columns={productHeaders}
        rows={rows}
        pageSize={props.pageSize}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        onRowClick={props.onRowClick}
        checkboxSelection
        disableSelectionOnClick
        disableColumnMenu
      />
    </div>
  )
}

DataGrid.defaultProps = {
  lang: 'en',
  data: [],
  selected: [],
  pageSize: 10,
}

export default DataGrid;
