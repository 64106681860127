import { FC } from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../theme";
import marked from "marked";
import { Container, Row, Col } from "react-bootstrap";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { TextWithBackground } from './TextWithBackground';

export interface CarouselProps {
  id: number;
  title: string;
  description: string;
  slide: any[];
  auto_play: boolean;
  speed: number;
  slide_per_view: number;
  indicators: boolean;
  text_offset?: number;
  padding_left?: string;
  padding_right?: string;
}

const useStyles = makeStyles<Theme, CarouselProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    fontSize: 'max(12px, calc(0.1em + 1vw))',
  },
  cardText: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  sliderCol: (props) => ({
    [theme.breakpoints.up(800)]: {
      paddingLeft: props.padding_left + '!important',
      paddingRight: props.padding_right + '!important',
    },
  }),
  "@global": {
    ".slick-slider": {
      minHeight: 300
    },
    ".slick-slider .slick-slide .content-body img": {
      display: "inline-block"
    },
    ".slick-slider ul.slick-dots": {
      bottom: 20,
      "&>li": {
        "@media (max-width: 800px)": {
          width: 20
        },
        "@media (max-width: 480px)": {
          width: 10
        },
        width: 50,
        height: 8,
        background: theme.palette.grey["400"],
        "&.slick-active": {
          background: theme.palette.primary.main,
        },
      },
      "&>li>button": {
        "&::before": { content: "unset" },
        width: "100%",
        height: "100%",
      },
    },
    ".slick-slider button.slick-arrow.slick-next": {
      right: 20,
      zIndex: 1,
    },
    ".slick-slider button.slick-arrow.slick-prev": {
      left: 20,
      zIndex: 1,
    },
    ".slick-slider button.slick-arrow": {
      width: 40,
      height: 40,
      border: `2px solid ${theme.palette.primary.main}`,
      boxSizing: 'borderBox',
      transform: 'rotate(-45deg)',
      position: 'absolute',
      "&.slick-next:before": {
        content: `url(/assets/images/navarrow.png)`,
        position: 'absolute',
        transform: 'rotate(45deg)',
        left: 0,
        top: 0,
      },
      "&.slick-prev:before": {
        content: `url(/assets/images/navarrow.png)`,
        position: 'absolute',
        transform: 'rotate(225deg)',
        top: 5,
        right: 0,
        left: 10,
      }
    },
    '.ql-editor ul > li::before': {
      content: "unset"
    }
  },
}));

const SCarousel: FC<CarouselProps> = (props) => {
  const settings = {
    slidesToShow: props.slide_per_view ? props.slide_per_view : 1,
    slidesToScroll: props.slide_per_view ? props.slide_per_view : 1,
    autoplay: props.auto_play,
    autoplaySpeed: props.speed,
    pauseOnHover: false,
    infinite: true,
    dots: props.indicators,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: props.slide_per_view > 2 ? 2 : 1,
          slidesToScroll: props.slide_per_view > 2 ? 2 : 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const slides = props.slide;
  const classes = useStyles(props);
  const text_offset = props.text_offset;

  return (
    <Container fluid={true} className={classes.root + '  ql-editor'} style={{ padding: 0 }}>
      <Row noGutters={true}>
        {props.title && <Col xs={{ span: 10, offset: 1 }} style={{ padding: 40, marginTop: 40 }}>
          <h1>{props.title}</h1>
        </Col>}
        {/* {<h1>{props.description}</h1>} */}
        {/* Quill automatically added <p><br></p> to monitor cursor */}
        {props.description && props.description?.replace("<p><br></p>", "") && <Col xs={{ span: 10, offset: 1 }} style={{ paddingBottom: 0 }}>
          <div
            className={`text-center`}
            dangerouslySetInnerHTML={{
              __html: marked(props.description),
            }}
          ></div>

        </Col>}
        <Col className={classes.sliderCol}>
          <Slider {...settings}>
            {slides.map((slide: any, i: number) => (
              <div key={i}>
                <TextWithBackground
                  text_offset={text_offset}
                  id={i}
                  description={slide.caption}
                  cover={slide.image}
                  alignment={slide.alignment}
                  color={slide.color}
                  isSingle={props.slide_per_view === 1}
                  tooltip={slide.tooltip}
                  height={slide.height}
                  width={slide.width}
                />
              </div>
            ))}
          </Slider>
        </Col>
      </Row>
    </Container>

  );
};

export default SCarousel;
