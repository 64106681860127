import { FC } from "react";

// components
import Image from '../../../components/common/Image';
import ImageWithFrame from "../../../components/common/ImageWithFrame";
import ImageWithFrameAndText from "../../../components/common/ImageWithFrameAndText";
import TextWithContainer from "../../../components/grid/TextWithContainer";
import TextWithBackground from '../../../components/TextWithBackground';
import TextWithBackground2 from "../../../components/angliss/TextWithBackground2";
import TextWithSideImage from '../../../components/TextWithSideImage';
import TextWithButton from "../../../components/TextWithButton";
import Images from "../../../components/angliss/Images";
import SCarousel from "../../../components/SCarousel";
import IFrame from '../../../components/IFrame';
import GoogleMap from "../../../components/GoogleMap";
// deprecated contact map
// import ContactMap from "../../../components/ContactMap";
import ContactMap from '../../../components/angliss/ContactMap';
import ImagePost from '../../../components/angliss/ImagePost';
import Gallery from '../../../components/angliss/Gallery';

// containers
import Posts from "../../../containers/angliss/Posts";
import Issuu from "../../../components/angliss/Issuu";

export interface ContentBuilderProps {
  id: number,
  [otherProps: string]: any,
  __component: string,
}

const ContentBuilder: FC<ContentBuilderProps> = (props) => {
  switch(props.__component) {
    case 'common.image': {
      const { id, ...rest } = props;

      return <Image id={id} {...rest} />
    }
    case 'common.image-with-frame': {
      const { id, ...rest } = props;

      return <ImageWithFrame id={id.toString()} {...rest} />
    }
    case 'common.image-with-frame-and-text': {
      const { id, ...rest } = props;

      return <ImageWithFrameAndText id={id.toString()} {...rest} />
    }
    case 'grid.text': {
      const { id, ...rest } = props;

      return (
        <TextWithContainer id={id} {...rest} />
      )
    }
    case 'page.text-with-background': {
      const { id, alignment, description, isSingle, ...rest} = props;
      return (
        <TextWithBackground id={id} alignment={alignment} description={description} isSingle={isSingle} {...rest} />
      )
    }
    case 'page.text-with-background2': {
      const { id, ...rest } = props;

      return (
        <TextWithBackground2 id={id} {...rest} />
      )
    }
    case 'page.text-with-side-image': {
      const { id, ...rest} = props;

      return (
        <TextWithSideImage id={id} {...rest} />
      )
    }
    case 'page.text-with-button': {
      const { id, ...rest } = props;

      return (
        <TextWithButton id={id} {...rest} />
      )
    }
    case 'page.posts': {
      const { id, ...rest } = props;

      return (
        <Posts id={id} {...rest} />
      )
    }
    case 'page.images': {
      const { id, ...rest } = props;

      return (
        <Images id={id.toString()} {...rest} />
      )
    }
    case 'carousel.carousel': {
      const { id, title, description, slide, speed, auto_play, slide_per_view, indicators, tooltip, ...rest } = props;

      return (
        <SCarousel 
          id={id}
          title={title}
          description={description}
          slide={slide}
          auto_play={auto_play}
          speed={speed}
          slide_per_view={slide_per_view}
          indicators={indicators}
          {...rest}
        />
      )
    }
    case 'embed.iframe': {
      const { id, title, url, css, ...rest } = props;

      return (
        <IFrame id={id} title={title} url={url} {...rest} />
      )
    }
    case 'embed.google-map': {
      const {
              id, api_key, latitude, longitude, zoom,
              ...rest
            } = props;

      return <GoogleMap
                id={id}
                api_key={api_key}
                latitude={latitude}
                longitude={longitude}
                zoom={zoom}
                {...rest}
             />
    }
    case 'embed.contact-map': {
      const {
              // GoogleMap props
              id, google_map,
              // ContactMarker props
              ...rest
            } = props;

      return <ContactMap
                id={id.toString()}
                google_map={google_map}
                {...rest}
             />
    }
    case 'embed.gallery': {
      const { id, ...rest } = props;
      return <Gallery id={id} {...rest} />
    }
    case 'embed.issuu': {
      const { id, ...rest } = props;
      return <Issuu id={id.toString()} {...rest} />
    }
    case 'page.angliss-imagepost': {
      const { id, image, title, excerpt, date, ...rest } = props;

      return <ImagePost id={id}
                image={image}
                title={title}
                excerpt={excerpt}
                date={date}
                {...rest}
             />;
    }
  }

  return (
    <></>
  )
};

export default ContentBuilder;
