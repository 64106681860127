import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme';
import { UploadFile } from '../../types/uploadFile';
import ImagePost, { ImagePostProps } from './ImagePost';

const useStyles = makeStyles((theme: Theme) => ({
  background: (props: TextWithBackground2Props) => ({
    backgroundColor: theme.palette.background.default,
    backgroundImage: `url(${props.cover?.url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: props.cover ? 500 : 0,
    width: props.cover_width ?? 'auto',
    height: props.cover_height ?? 'auto',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "40px 5px",
    [theme.breakpoints.down(768)]: {
      padding: '2rem!important',
      height: 'auto!important',
    },
    [theme.breakpoints.down(576)]: {
      padding: '0rem!important',
      height: 'auto!important',
    },
  }),
  empty: (props: TextWithBackground2Props) => ({}), 
  text: (props: TextWithBackground2Props) => ({
    color: props.text_color,
  }),
}));

export type TextAlignmentType = 'left' | 'right';

export interface TextWithBackground2Props extends ImagePostProps {
  id: number;
  cover?: UploadFile;
  cover_width?: string;
  cover_height?: string;
  page_fullwidth?: boolean;
  text?: string;
  text_alignment?: TextAlignmentType;
  text_color?: string;
  grid_empty?: number;
  grid_empty_offset?: number;
  grid_text?: number;
  grid_text_offset?: number;
}

/**
 * FIXME: responsive not finish
 */
export const TextWithBackground2: FC<TextWithBackground2Props> = (props) => {
  const classes = useStyles(props);

  const isMobile = window.screen.width <= 768 ? true : false;
  return (
    <section className={classes.background}>
      <ImagePost
        id={props.id}
        page_fullwidth={props.page_fullwidth || isMobile}
        image_alignment={props.text_alignment === 'right' ? 'left' : 'right'}
        style_image={classes.empty}
        grid_image={props.grid_empty}
        grid_image_offset={props.grid_empty_offset}
        style_title={classes.text}
        style_excerpt={classes.text}
        excerpt={props.text}
        grid_text={props.grid_text}
        grid_text_offset={props.grid_text_offset}
      />
    </section>
  );
};

TextWithBackground2.defaultProps = {
  cover_width: 'auto',
  cover_height: 'auto',
  page_fullwidth: true,
  text: '',
  text_alignment: 'left',
  text_color: 'black',
  grid_empty: 6,
  grid_empty_offset: 0,
  grid_text: 6,
  grid_text_offset: 0,
};

export default TextWithBackground2;
