import React, { FC } from 'react';
import { Page, View, Image, Text, StyleSheet, Font } from '@react-pdf/renderer';
import _ from 'lodash';

import { ProductTableData } from '../ProductPdfViewer';
import ProductTableRow from './ProductTableRow';

Font.register({
  family: 'PMingLiU',
  src: require('./PMINGLIU.ttf').default,
  fontStyle: 'normal',
  fontWeight: 'normal',
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'PMingLiU',
    flexDirection: 'column',
    fontSize: '12px',
    padding: '20px 10px 20px 10px',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    padding: '8px',
    width: '200px',
  },
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '0px',
  },
  footer: {
    marginTop: 'auto',
  },
  pageNo: {
    textAlign: 'right',
  },
  contact: {
    textAlign: 'left',
  },
});

export interface ProductTableProps {
  products: ProductTableData[];
}

const ProductTable: FC<ProductTableProps> = (props) => {
  const productsPerPage = 16;
  const totalPageNo = Math.ceil(props.products.length / productsPerPage);
  const indexes = _.fill(Array(totalPageNo), 1);

  const logo = require('./pdf_logo.png');
  return <>
    {
      indexes.map((ignore, index) => (
        <Page size='A4' style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.logo} src={logo.default} />
          </View>
          <View style={styles.table}>
            <ProductTableRow products={props.products.slice(index * productsPerPage, index * productsPerPage + productsPerPage)} />
          </View>
          <View style={styles.footer}>
            <Text style={styles.pageNo}>Page {index + 1}</Text>
            <Text style={styles.contact}>Customer Service Hotline 客戶服務熱線: (852) 2494 2112</Text>
            <Text style={styles.contact}>Email電郵: info@angliss.com.hk</Text>
            <Text style={styles.contact}>www.angliss.com.hk</Text>
          </View>
        </Page>
      ))
    }
  </>
}

ProductTable.defaultProps = {
  products: [],
}

export default ProductTable;
