import React, { FC, useState, useLayoutEffect } from 'react';
import { Row, Col, Accordion, Button, Navbar, Nav } from 'react-bootstrap';
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';

import { Theme } from '../../../theme';
import useLanguage from '../../../hooks/useLanguage';

const useStyles = makeStyles<Theme, ProductCategoryBuilderProps>((theme) => ({
  root: (props) => ({
    overflow: 'scroll',
    marginLeft: '-3vw',
  }),
  accordion: (props) => ({
    whiteSpace: 'nowrap',
  }),
  toggle: (props) => ({
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white',
    },
    whiteSpace: 'nowrap',
  }),
  collapse: (props) => ({
    whiteSpace: 'nowrap',
  }),
  cat2Col: (props) => ({
    paddingLeft: '3rem',
  }),
}));

export interface ProductCategoryGroup {
  [category: string]: ProductCategory[];
}

export interface ProductCategory {
  key?: string;
  cat1?: string;
  cat2?: string;
}

export interface ProductCategoryBuilderProps {
  data?: ProductCategoryGroup; // data must init cat.key
  sortSequence?: string[]; //cat1 sort sequence
  defaultSelectedCat1?: string[]; // cat1 name
  selectedCat1?: string[]; // cat1 name
  selectedCat2?: string[]; // cat2 keys
  onToggleClicked?: (event: React.SyntheticEvent, eventKey: string) => void;
  onCat1CheckboxClicked?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat1Checked: Map<string, boolean>) => void;
  onCat2CheckboxClicked?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat2Checked: Map<string, boolean>) => void;
}

const ProductCategoryBuilder: FC<ProductCategoryBuilderProps> = (props): JSX.Element => {
  const classes = useStyles(props);
  const { lang } = useLanguage();

  const initCat1 = new Map<string, boolean>();
  const initCat2 = new Map<string, boolean>();
  Object.keys(props.data!).forEach((cat1) => {
    initCat1.set(cat1, props.defaultSelectedCat1![0].indexOf(cat1) > -1);

    props.data![cat1].forEach((cat) => {
      initCat2.set(cat.key!, props.defaultSelectedCat1![0].indexOf(cat.cat1!) > -1);
    })
  })

  const [cat1Checked, setCat1Checked] = useState<Map<string, boolean>>(initCat1);
  const [cat2Checked, setCat2Checked] = useState<Map<string, boolean>>(initCat2);

  const handleCat1ToggleClick = (event: React.SyntheticEvent, index: number) => {
    props.onToggleClicked?.(event, index.toString())
  }

  const handleCat1CheckboxClicked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat1: string) => {
    cat1Checked.set(cat1, checked);
    if (checked) {
      const allSubCat2Keys = props.data![cat1]?.map(cat => cat.key);
      allSubCat2Keys?.forEach((cat2Key) => {
        cat2Checked.set(cat2Key!, true);
      })
      setCat2Checked(_.cloneDeep(cat2Checked));
    } else if (!checked) {
      const allSubCat2Keys = props.data![cat1]?.map(cat => cat.key);
      allSubCat2Keys?.forEach((cat2Key) => {
        cat2Checked.set(cat2Key!, false);
      })
      setCat2Checked(_.cloneDeep(cat2Checked));
    }

    props.onCat1CheckboxClicked?.(event, checked, cat1Checked);
    props.onCat2CheckboxClicked?.(event, checked, cat2Checked);
  }

  const handleCat2CheckboxClicked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat2Key: string) => {
    cat2Checked.set(cat2Key, checked);
    setCat2Checked(_.cloneDeep(cat2Checked));

    let checkedCat2CheckboxNo = 0;
    const cat1 = cat2Key.substring(0, cat2Key.indexOf('-'));
    const allSubCat2Keys = props.data![cat1]?.map(cat => cat.key);
    allSubCat2Keys?.forEach((cat2Key) => {
      if (cat2Checked.get(cat2Key!)) {
        checkedCat2CheckboxNo++;
      }
    })

    if (checkedCat2CheckboxNo === 0) {
      cat1Checked.set(cat1, false);
      setCat1Checked(_.cloneDeep(cat1Checked));
    }

    if (checkedCat2CheckboxNo === props.data![cat1]?.length) {
      cat1Checked.set(cat1, true);
      setCat1Checked(_.cloneDeep(cat1Checked));
      props.onCat1CheckboxClicked?.(event, checked, cat1Checked);
    }

    props.onCat2CheckboxClicked?.(event, checked, cat2Checked);
  }

  let cat1s: any[] = [];
  if (props.sortSequence) {
    cat1s = props.sortSequence;
  }

  // remove empty category
  const dataCat1Keys = Object.keys(props.data!);
  const notFoundIndexes: number[] = [];
  cat1s.forEach((cat1, index) => {
    if (dataCat1Keys.indexOf(cat1) === -1) {
      notFoundIndexes.push(index);
    }
  })
  notFoundIndexes.reverse().forEach(value => cat1s.splice(value, 1));

  const [rootWidth, setRootWidth] = useState<string>('auto');
  const [rootHeight, setRootHeight] = useState<string>('100%');
  useLayoutEffect(() => {
    switch(lang) {
      case 'zh-Hant':
        if (window.innerWidth > 1024) {
          setRootWidth('16.5vw');
        } else if (window.innerWidth > 768) {
          setRootWidth('24vw');
        }
        break;
      case 'en':
        if (window.innerWidth > 1024) {
          setRootWidth('16.5vw');
        } else if (window.innerWidth > 768) {
          setRootWidth('24vw');
        }
        break;
    }

    const datagridDiv = document.querySelector('#product_data_grid');

    if (datagridDiv) {
      setRootHeight(datagridDiv.clientHeight.toString() + 'px');
    }
  }, [lang, rootWidth, rootHeight]);

  return (
    <Navbar expand="sm">
      <Navbar.Toggle aria-controls="category-navbar-nav"></Navbar.Toggle>
      <Navbar.Collapse id="category-navbar-nav">
        <Nav>
          <div className={classes.root} style={{ width: rootWidth, height: rootHeight }}>
            {
              cat1s.map(
                (cat1, index) => {
                  let cat2s = props.data![cat1];
                  cat2s?.sort();
                  let checkedCat2CheckboxNo = 0;
                  const allSubCat2Keys = props.data![cat1]?.map(cat => cat.key);
                  allSubCat2Keys?.forEach((cat2Key) => {
                    if (cat2Checked.get(cat2Key!)) {
                      checkedCat2CheckboxNo++;
                    }
                  })

                  return <Accordion
                    className={classes.accordion}
                    key={index}
                    defaultActiveKey={props.defaultSelectedCat1!.indexOf(cat1) > -1 ? index.toString() : ''}
                  >
                    <Checkbox
                      defaultChecked={props.defaultSelectedCat1!.indexOf(cat1) > -1}
                      checked={cat1Checked.get(cat1)}
                      indeterminate={0 < checkedCat2CheckboxNo && checkedCat2CheckboxNo < props.data![cat1].length}
                      onChange={(event, checked) => handleCat1CheckboxClicked(event, checked, cat1)}
                      inputProps={{ 'aria-label': cat1, 'aria-rowindex': index }}
                    />
                    <Accordion.Toggle
                      className={classes.toggle}
                      as={Button}
                      variant='link'
                      eventKey={index.toString()}
                      onClick={(event) => handleCat1ToggleClick(event, index)}
                    >
                      <FontAwesomeIcon icon={faCaretRight} />
                      {' ' + cat1}
                    </Accordion.Toggle>
                    <Accordion.Collapse className={classes.collapse} eventKey={index.toString()}>
                      <>
                        {
                          cat2s?.map((category, i) => {
                            return (
                              <Row key={i}>
                                {
                                  <Col className={classes.cat2Col}>
                                    <Checkbox
                                      defaultChecked={props.defaultSelectedCat1!.indexOf(category.cat1!) > -1}
                                      checked={cat2Checked.get(category.key!)}
                                      onChange={(event, checked) => handleCat2CheckboxClicked(event, checked, category.key!)}
                                      inputProps={{ 'aria-label': category.key, 'aria-rowindex': i }}
                                    />
                                    {category.cat2}
                                  </Col>
                                }
                              </Row>
                            );
                          })
                        }
                      </>
                    </Accordion.Collapse>
                  </Accordion>
                }
              )
            }
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

ProductCategoryBuilder.defaultProps = {
  data: {},
  defaultSelectedCat1: [],
  selectedCat1: [],
  selectedCat2: [],
}

export default ProductCategoryBuilder;
