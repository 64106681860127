import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import Text from '../common/Text';

const useStyles = makeStyles<Theme, TextWithContainerProps>((theme) => ({
  container: (props) => ({
    height: props.page_height,
  }),
  row: (props) => ({}),
  col: (props) => ({}),
  text: (props) => ({}),
}));

export interface TextWithContainerProps {
  id: number;
  html?: string;
  grid_width?: number;
  grid_width_offset?: number;
  page_height?: string;
  page_fullwidth?: boolean;
}

export const TextWithContainer: FC<TextWithContainerProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Container className={classes.container} fluid={props.page_fullwidth}>
      <Row className={classes.row}>
        <Col className={classes.col}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: props.grid_width, offset: props.grid_width_offset }}
        >
          <Text className={classes.text} html={props.html} />
        </Col>
      </Row>
    </Container>
  )
}

TextWithContainer.defaultProps = {
  html: '',
  grid_width: 12,
  grid_width_offset: 0,
  page_height: 'auto',
  page_fullwidth: true,
};

export default TextWithContainer;
