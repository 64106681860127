import React, { FC } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import marked from 'marked';

import { Theme } from '../theme';
import { UploadFile } from '../types/uploadFile';

export type AlignmentType = 'left' | 'right';

export interface TextWithSideImageProps {
    id: number,
    description?: string,
    image?: UploadFile,
    image_alignment?: AlignmentType,
    image_width?: string,
    image_height?: string,
    page_fullwidth?: boolean,
    grid_image?: number,
    grid_image_offset?: number,
    grid_text?: number,
    grid_text_offset?: number,
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    container: {
        height: '100%',
        alignItems: 'center',
    },
    row: {
        [theme.breakpoints.up(576)]: {
            marginTop: 60,
            marginBottom: 60,
        },
        [theme.breakpoints.down(576)]: {
            marginBottom: '2rem',
            '&.right' : {
                flexDirection: 'column-reverse',
            }
        },
    },
    col: {
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        [theme.breakpoints.down(576)]: {
            marginBottom: 10
        },
    },
    image: {
        width: (props: TextWithSideImageProps) => props.image_width ?? '100%',
        height: (props: TextWithSideImageProps) => props.image_height ?? '100%',
    },
    text: {
    }
}));

/**
 * @deprecated
 */
const TextWithSideImage: FC<TextWithSideImageProps> = (props) => {
    const classes = useStyles(props);

    const imageSpanSize = (alignment: AlignmentType, location: AlignmentType): number => {
        if (alignment === location) {
            return props.grid_image ?? 6;
        } else if (alignment !== location) {
            return props.grid_text ?? 6;
        }
        
        return 12;
    }

    const imageOffsetSize = (alignment: AlignmentType, location: AlignmentType): number => {
        if (alignment === location) {
            return props.grid_image_offset ?? 0;
        } else if (alignment !== location) {
            return props.grid_text_offset ?? 0;
        }
        
        return 0;
    }

    const imageLocation = (alignment: AlignmentType, location: AlignmentType): JSX.Element => {
        if (alignment === location && props.image) {
            return  <Image
                        className={classes.image}
                        src={props.image.url}
                        fluid
                    />
        } else if (alignment !== location && props.description) {
            return  <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: marked(props.description ?? ''),
                        }}
                    ></div>
        }

        return <></>
    }

    const image_alignment = props.image_alignment ?? 'left';

    return (
        <Container fluid={props.page_fullwidth ?? true} className={classes.container}>
            <Row className={classes.row + ' ' + image_alignment}>
                <Col
                    className={classes.col}
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: imageSpanSize(image_alignment, 'left'), offset: imageOffsetSize(image_alignment, 'left') }}
                >
                    {imageLocation(image_alignment, 'left')}
                </Col>
                <Col
                    className={classes.col}
                    xs={{ span: 12, offset: 0 }}
                    sm={{ span: imageSpanSize(image_alignment, 'right'), offset: imageOffsetSize(image_alignment, 'right') }}
                >
                    {imageLocation(image_alignment, 'right')}
                </Col>
            </Row>
        </Container>
    )
}

TextWithSideImage.defaultProps = {
    description: '',
    image_alignment: 'left',
    image_width: '100%',
    image_height: '100%',
    page_fullwidth: false,
    grid_image: 5,
    grid_image_offset: 0,
    grid_text: 7,
    grid_text_offset: 0
}

export default TextWithSideImage;
