import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Page, { PageProps } from "../../../components/Page";
import { Theme } from "../../../theme";
import ContentBuilder from "./ContentBuilder";
import axiosInstance from "../../../utils/axios";
import { API_URL, LANAGUAGE_PAGES } from "../../../constants";
import { PageObject } from '../../../types/pageObject';
import { Container, Row, Col } from "react-bootstrap";
import Spinner from '../../../components/common/Spinner';
import 'react-quill/dist/quill.snow.css';
import useLanguage from "../../../hooks/useLanguage";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    container: {
        marginTop: 20,
        marginBottom: 20
    },
    row: {
        "&:not(:last-child)": {
            marginBottom: 20
        }
    },
    pageTitle: {
        textAlign: 'center'
    },
    '@global': {
        'h1': {
            fontSize: 'max(30px, 3em)',
        },
        'h2': {
            fontSize: 'max(25px, 2.5em)',
        },
        'h3': {
            fontSize: 'max(20px, 2.2em)',
        },
        'h4': {
            fontSize: 'max(18px, 2em)',
        },
        'h5': {
            fontSize: 'max(16px, 1.8em)',
        },
        'h6': {
            fontSize: 'max(14px, 1.5em)',
        },
        '.light': {
            color: theme.palette.common.white
        },
        '.dark': {
            color: theme.palette.common.black
        },
        'a': {
            color: theme.palette.primary.main,
        },
        '.MuiCheckbox-root.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main,
        }
    }

}));

export interface PageViewProps extends PageProps {
    excerpt?: string;
    description?: string;
    cover?: string;
}

const PageView: FC<PageViewProps> = () => {
    const classes = useStyles();
    const { lang } = useLanguage();
    const cachedMainNavigation = localStorage.getItem('mainNavigation');
    const mainNavigation = cachedMainNavigation ? JSON.parse(cachedMainNavigation) : null;
    const url = new URL(window.location.href);
    const [pageObject, setPageObject] = useState<PageObject>({ title: '', description: '', content: [], categories: [] });
    const [loading, setLoading] = useState(true);

    if (!mainNavigation) {
        window.location.href = "/404";
    }

    const getPageData = useCallback(async () => {
        setLoading(true);
        try {
            const pagesData = sessionStorage.getItem(LANAGUAGE_PAGES);
            let langPages = pagesData && JSON.parse(pagesData);
            if (!langPages?.[lang]) {
                let { data: pages } = await axiosInstance.get(`${API_URL}/pages?_locale=${lang}`);
                pages = pages?.map((item: any) => ({ id: item.id, path: item.path === '/home' ? '/' : item.path }));

                if (!langPages) {
                    langPages = { [lang]: pages };
                } else {
                    langPages[lang] = pages;
                }
                sessionStorage.setItem(LANAGUAGE_PAGES, JSON.stringify(langPages));
            }
            const [currentPage] = langPages?.[lang]?.filter((item: any) => item?.path?.replaceAll('/', '') === url.pathname.replaceAll('/', ''));
            const currentPageId = currentPage?.id;
            const result = await axiosInstance.get(`${API_URL}/pages/${currentPageId}`);
            setPageObject(result.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [url.pathname, lang]);

    useEffect(() => {
        getPageData();
    }, [getPageData])


    return (
        <Page className={classes.root} title="Angliss" font={pageObject?.font}>
            {loading && <Spinner />}
            {/* Quill automatically added <p><br></p> to monitor cursor */}
            {!loading && pageObject.description?.replace("<p><br></p>", "") &&
                <Container className={classes.container}>
                    {pageObject.description && <Row>
                        <Col className="ql-editor">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: pageObject.description,
                                }}
                            ></div>
                        </Col>
                    </Row>}
                </Container>}
            {!loading && pageObject.content.map((content: any, i) => {
                const props = { ...content, categoriesProp: pageObject.categories };
                return <ContentBuilder key={i} {...props} />
            })}
        </Page>
    );
};

export default PageView;
