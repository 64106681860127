import React, { FC, useLayoutEffect, useState } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { Theme } from '../../../theme';
import { UploadFile } from '../../../types/uploadFile';
import ImageWithFrameAndText, { ImageWithFrameAndTextProps } from '../../common/ImageWithFrameAndText';

const useStyles = makeStyles<Theme, HighlightSlideModalCarouselProps>((theme) => ({
  slider: (props) => ({}),
  slide: (props) => ({
    cursor: 'pointer',
  }),
  image: (props) => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
  }),
  nextArrow: {
    right: '-8px',
    width: '2.5rem',
    height: '2.5rem',
    zIndex: 100,
  },
  prevArrow: {
    left: '-10px',
    width: '2.5rem',
    height: '2.5rem',
    zIndex: 100,
  },
  '@global': {
    '.slick-dots li.slick-active button::before': {
      color: theme.palette.primary.main,
    },
    '.slick-dots li button::before': {
      color: theme.palette.primary.main,
    },
  },
}));

export interface HighlightSlideModalCarouselSlide {
  id?: string;
  image?: UploadFile;
  sequence?: number;
}

export interface HighlightSlideModalCarouselProps {
  className?: string;
  slides?: ImageWithFrameAndTextProps[];
  slide?: ImageWithFrameAndTextProps;
  slide_per_view?: number;
  slide_to_scroll?: number;
  auto_play?: boolean;
  auto_play_speed?: number;
  pause_on_hover?: boolean;
  infinite?: boolean;
  indicators?: boolean;
  nextArrow?: JSX.Element;
  prevArrow?: JSX.Element;
}

function PrevArrow(props: any) {
  const { className, clazz, onClick } = props;
  return (
    <img onClick={onClick} className={className + ' ' + clazz} src="/assets/images/arrow_prev.png" alt="arrow" />
  );
}

function NextArrow(props: any) {
  const { className, clazz, onClick } = props;
  return (
    <img onClick={onClick} className={className + ' ' + clazz} src="/assets/images/arrow_next.png" alt="arrow" />
  );
}

const HighlightSlideModalCarousel: FC<HighlightSlideModalCarouselProps> = (props) => {
  const classes = useStyles(props);

  const [slides, setSlides] = useState<ImageWithFrameAndTextProps[]>([]);

  useLayoutEffect(() => {
    if (props.slides && props.slide) {
      let orderedSlides = _.clone(props.slides);
      const currentSlideIndex = props.slides.findIndex(slide => slide.id === props.slide?.id)
      if (currentSlideIndex > 0) {
        const splicedSlides: ImageWithFrameAndTextProps[] = orderedSlides.splice(0, currentSlideIndex);
        orderedSlides.push(...splicedSlides);
        setSlides(orderedSlides);
      } else {
        setSlides(props.slides);
      }
    } else if (props.slides) {
      setSlides(props.slides);
    }
  }, [props.slide, props.slides]);

  const settings = {
    slidesToShow: props.slide_per_view,
    slidesToScroll: props.slide_per_view,
    autoplay: props.auto_play,
    autoplaySpeed: props.auto_play_speed,
    pauseOnHover: props.pause_on_hover,
    infinite: props.infinite,
    dots: props.indicators,
    prevArrow: <PrevArrow clazz={classes.prevArrow} />,
    nextArrow: <NextArrow clazz={classes.nextArrow} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
          slidesToScroll: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
          autoplay: props.auto_play,
          autoplaySpeed: props.auto_play_speed,
          pauseOnHover: props.pause_on_hover,
          infinite: props.infinite,
          dots: props.indicators,
          nextArrow: props?.nextArrow,
          prevArrow: props?.prevArrow,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: props.auto_play,
          autoplaySpeed: props.auto_play_speed,
          pauseOnHover: props.pause_on_hover,
          infinite: props.infinite,
          dots: props.indicators,
          nextArrow: props?.nextArrow,
          prevArrow: props?.prevArrow,
        },
      },
    ],
  };

  return (
    <>
      {
        slides.length === 0 ?
          <ImageWithFrameAndText className={classes.slider + (props.className ? ' ' + props.className : '')} />
        :
          <Slider className={classes.slider + (props.className ? ' slider ' + props.className : '')} {...settings}>
            {
              slides.map((slide: ImageWithFrameAndTextProps, i: number) => (
                <div key={i} className={classes.slide}>
                  <ImageWithFrameAndText className={classes.image} {...slide} />
                </div>
              ))
            }
          </Slider>
      }
    </>
  )
}

HighlightSlideModalCarousel.defaultProps = {
  slides: [],
  slide_per_view: 1,
  auto_play: false,
  pause_on_hover: false,
  infinite: true,
  indicators: false,
}

export default HighlightSlideModalCarousel;
