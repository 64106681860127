import React, { FC, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import IFrame from '../common/IFrame';

const useStyles = makeStyles<Theme, IssuuProps>((theme) => ({
  image: (props) => ({
    width: '100%',
    height: '100%',
  }),
  root: (props) => ({
    position: 'relative',
    paddingTop: 'max(60%,326px)',
    width: '100%',
    height: 0,
  }),
  iframe: (props) => ({
    position: 'absolute',
    border: 'none',
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }),
}));

export interface IssuuProps {
  id?: string;
  iframe_title?: string;
  iframe_url?: string;
}

const Issuu: FC<IssuuProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  const iframeElm = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    iframeElm.current?.requestFullscreen()
                      .catch(e => console.error);
  }, []);
  
  return (
    <div className={classes.root}>
      <IFrame className={classes.iframe}
        id={props.id}
        title={props.iframe_title}
        url={props.iframe_url}
        allowFullScreen={true}
        sandbox={'allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups'}
        ref={iframeElm}
      />
    </div>
  )
}

Issuu.defaultProps = {
  iframe_title: '',
  iframe_url: '',
};

export default Issuu;
