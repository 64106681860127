import React, { FC, Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { ProductTableData } from '../ProductPdfViewer';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid black',
  },
  code: {
    width: '15%',
  },
  desc: {
    // width: '35%', with code
    width: '50%',
  },
  spec: {
    width: '30%',
  },
  country: {
    width: '20%',
  },
});

export interface ProductTableRowProps {
  products: ProductTableData[];
}

const ProductTableRow: FC<ProductTableRowProps> = (props) => {

  const header =
    <View style={styles.row} key='header'>
      {/* <Text style={styles.code}>Code</Text> */}
      <Text style={styles.desc}>Product Description</Text>
      <Text style={styles.spec}>Weight / UOM</Text>
      <Text style={styles.country}>Country of Origin</Text>
    </View>

  const rows = props.products.map(product =>
    <View style={styles.row} key={product.id}>
      {/* <Text style={styles.code}>{product.prod_no}</Text> */}
      <Text style={styles.desc}>{product.prod_desc_web}</Text>
      <Text style={styles.spec}>{product.spec}</Text>
      <Text style={styles.country}>{product.country_name}</Text>
    </View>
  );

  return (
    <Fragment>
      {header}
      {rows}
    </Fragment>
  );
};

ProductTableRow.defaultProps = {
  products: [],
}

export default ProductTableRow
