import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../theme/index';
import Image, { ImageProps } from '../common/Image';
import Post, { PostProps } from './Post';

const useStyles = makeStyles<Theme, ImagePostProps>((theme) => ({
  container: (props) => ({}),
  row: (props) => ({
    [theme.breakpoints.up(576)]: { // bootstrap breakpoint sm
      marginTop: 60,
      marginBottom: 60,
    },
    [theme.breakpoints.down(576)]: { // bootstrap breakpoint sm
      marginBottom: '2rem',
      '&.reverse': {
        flexDirection: 'column-reverse',
      },
    },
  }),
  col: (props) => ({}),
  date: {
    color: '#AEAEB2',
  },
  title: (props) => ({}),
  image: (props) => ({}),
  post: (props) => ({
    '@media (max-width: 576px)': { // bootstrap breakpoint sm
      // lineHeight: '1.0',
    },
  }),
  post_title: (props) => ({
    '@media (max-width: 576px)': { // bootstrap breakpoint sm
      marginTop: '1rem',
    },
  }),
  post_excerpt: (props) => ({}),
}));

export type ImageAlignmentType = 'left' | 'right';

export interface ImagePostProps extends ImageProps, PostProps {
  id: number;
  image_alignment?: ImageAlignmentType;
  page_fullwidth?: boolean;
  grid_image?: number;
  grid_image_offset?: number;
  grid_text?: number;
  grid_text_offset?: number;
  style_image?: string;
}

const ImagePost: FC<ImagePostProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  const imageSpanSize = (alignment: ImageAlignmentType, location: ImageAlignmentType): number => {
    const { grid_image, grid_text } = props;

    if (alignment === location && grid_image) {
      return grid_image;
    } else if (alignment !== location && grid_text) {
      return grid_text;
    }

    return 12;
  }

  const imageOffsetSize = (alignment: ImageAlignmentType, location: ImageAlignmentType): number => {
    const { grid_image_offset, grid_text_offset } = props;

    if (alignment === location && grid_image_offset) {
      return grid_image_offset;
    } else if (alignment !== location && grid_text_offset) {
      return grid_text_offset;
    }

    return 0;
  }

  const imageComponent = (alignment: ImageAlignmentType, location: ImageAlignmentType): JSX.Element => {
    const { image } = props;
    const { title, excerpt, date } = props;

    if (alignment === location && image) {
      /* fallback to image */
      // return <ImageWithFrame
      //           className={classes.image + ' ' + props.style_image}
      //           frame_width={'100%'} frame_height={'frame_width'}
      //           image_url={image.url} image_width={image.width} image_height={image.height}
      //        />
      return <Image
                className={classes.image + ' ' + props.style_image}
                image={image}
                image_width={'100%'} image_height={'15rem'}
             />
    } else if (alignment !== location && excerpt) {
      return <Post
                className={classes.post}
                style_title={classes.post_title + (props.style_title ? ' ' + props.style_title : '')}
                title={title}
                style_excerpt={classes.post_excerpt + (props.style_excerpt ? ' ' + props.style_excerpt : '')}
                excerpt={excerpt} date={date}
              />
    }

    return <></>
  }

  const image_alignment = props.image_alignment!;
  return (
    <Container fluid={props.page_fullwidth}>
      <Row className={classes.row + ' ' + (image_alignment === 'right' ? 'reverse' : '')}>
        <Col
          className={classes.col}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: imageSpanSize(image_alignment, 'left'), offset: imageOffsetSize(image_alignment, 'left') }}
        >
          {imageComponent(image_alignment, 'left')}
        </Col>
        <Col
          className={classes.col}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 12, offset: 0 }}
          md={{ span: imageSpanSize(image_alignment, 'right'), offset: imageOffsetSize(image_alignment, 'right') }}
        >
          {imageComponent(image_alignment, 'right')}
        </Col>
      </Row>
    </Container>
  );
}

ImagePost.defaultProps = {
  image_alignment: 'left',
  page_fullwidth: false,
  grid_image: 5,
  grid_image_offset: 0,
  grid_text: 7,
  grid_text_offset: 0,
}

export default ImagePost;
