import React, { FC, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import Text from '../common/Text';

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: PostProps) => ({}),
  row: (props: PostProps) => ({
    flexDirection: 'column',
  }),
  col: (props: PostProps) => ({}),
  col1: (props: PostProps) => ({
    alignSelf: 'start',
  }),
  col2: (props: PostProps) => ({
    flexGrow: 2,
  }),
  col3: (props: PostProps) => ({
    alignSelf: 'end',
  }),
  title: (props: PostProps) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
  }),
  excerpt: (props: PostProps) => ({}),
  date: (props: PostProps) => ({
    color: '#AEAEB2',
  }),
}));

export interface PostProps {
  className?: string;
  style_title?: string;
  title?: string;
  style_excerpt?: string;
  excerpt?: string;
  style_date?: string;
  date?: Date;
}

const Post: FC<PostProps> = (props): JSX.Element => {
  const classes = useStyles(props);
  // const [height, setHeight]= useState<number>(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const { title, excerpt, date } = props;
  const months = ['Jan', 'Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // useLayoutEffect(() => {
  //   if (rootRef.current) {
  //     setHeight(rootRef.current.offsetWidth * 3 / 4);
  //   }
  // }, [rootRef.current?.offsetWidth]);

  return (
    <Container className={classes.container + (props.className ? ' ' + props.className : '')} /*style={{height}}*/ ref={rootRef}>
      <Row className={classes.row}>
        {
          title &&
          <Col className={classes.col + ' ' + classes.col1 + (props.style_title ? ' ' + props.style_title : '')}>
            <Text className={classes.title}
              html={title}
            />
          </Col>
        }
        {
          excerpt &&
          <Col className={classes.col + ' ' + classes.col2 + (props.style_excerpt ? ' ' + props.style_excerpt : '')}>
            <Text className={classes.excerpt}
              html={excerpt}
            />
          </Col>
        }
        {
          date &&
          <Col className={classes.col + ' ' + classes.col3 + (props.style_date ? ' ' + props.style_date : '')}>
            <Text className={classes.date}
              html={months[date.getMonth()] + ' ' + date.getDate() + '.' + date.getFullYear()}
            />
          </Col>
        }
      </Row>
    </Container>
  );
}

Post.defaultProps = {
  title: '',
  excerpt: '',
  style_title: '',
  style_excerpt: '',
  style_date: '',
};

export default Post;
