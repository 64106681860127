import React, { FC } from 'react';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Theme } from '../../../theme';

const useStyles = makeStyles<Theme, ArrowBreadcumbsProps>((theme) => ({
  link: (props) => ({
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
  activeLink: (props) => ({
    color: theme.palette.primary.main,
  }),
}));

const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;

export interface ArrowBreadcumbsProps {
  className?: string;
  names?: string[];
  links: string[];
}

const ArrowBreadcumbs: FC<ArrowBreadcumbsProps> = (props) => {
  const classes = useStyles(props);

  const names = props.names ?? props.links!;
  const paths = props.links!;

  return (
    <div className={props.className}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {paths.map((value, index) => {
          const to = `${paths.slice(0, index + 1).join('/').replace(/\/+/g, '/')}`;
          const last = index === paths.length - 1;

          return last ? (
            <Typography className={classes.activeLink} key={to}>
              {names[index]}
            </Typography>
          ) : (
            <LinkRouter className={classes.link} color="inherit" to={to} key={to}>
              {names[index]}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </div>
  )
}

ArrowBreadcumbs.defaultProps = {
  links: ['/'],
}

export default ArrowBreadcumbs;
