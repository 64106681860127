import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PDFViewer, Document } from '@react-pdf/renderer';

import { Theme } from '../../../theme';
import ProductTablePage from './pdf/ProductTablePage';

const useStyles = makeStyles<Theme, ProductPdfViewerProps>((theme) => ({
  viewer: (props) => ({
    width: '100%',
    height: '50rem',
  }),
}));

export interface ProductTableData {
  id?: number;
  prod_no?: string;
  prod_desc_web?: string;
  spec?: string;
  country_name?: string;
}

export interface ProductPdfViewerProps {
  products?: ProductTableData[];
}

// Create styles
// const styles = StyleSheet.create({
// });

const ProductPdfViewer: FC<ProductPdfViewerProps> = (props): JSX.Element  => {
  const classes = useStyles(props);

  return (
    <PDFViewer className={classes.viewer}>
      <Document>
        <ProductTablePage products={props.products!}/>
      </Document>
    </PDFViewer>
  )
}

ProductPdfViewer.defaultProps = {
  products: [],
}

export default ProductPdfViewer;
