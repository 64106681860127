import React, { FC } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';
import { UploadFile } from '../../types/uploadFile';

const useStyles = makeStyles((theme: Theme) => ({
  image: (props: ImageProps) => ({}),
}));

export interface ImageProps {
  id?: number;
  className?: string;
  image?: UploadFile;
  image_width?: string;
  image_height?: string;
  image_fluid?: boolean;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
}

const Image: FC<ImageProps> = (props) => {
  const classes = useStyles(props);

  return (
    <BootstrapImage
      id={props.id?.toString()}
      className={classes.image + ' ' + props.className}
      src={props.image?.url}
      width={props.image_width}
      height={props.image_height}
      fluid={props.image_fluid}
      onClick={(e) => props.onClick?.(e)}
    />
  );
}

Image.defaultProps = {
  image_width: '100%',
  image_height: '100%',
  image_fluid: true,
}

export default Image;
