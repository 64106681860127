import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';
import Product from '../../../types/Product';

const useStyles = makeStyles<Theme, ProductDetailProps>((theme) => ({
  prod_desc_web: (props) => ({}),
  cat1: (props) => ({}),
  cat2: (props) => ({}),
  brand: (props) => ({}),
  spec: (props) => ({}),
  stor_con: (props) => ({}),
  country_name: (props) => ({}),
}));

export interface ProductDetailProps extends Product {
  className?: string;
  lang?: SupportedLanguages;
  prod_no?: string;
  prod_desc_web?: string;
  cat1?: string;
  cat2?: string;
  brand?: string;
  spec?: string;
  stor_con?: string;
  country_name?: string;
}

const ProductDetail: FC<ProductDetailProps> = (props) => {
  const classes = useStyles(props);
  const languages = require('./DetailLang.json');
  const { lang } = props;
  return (
    <div className={props.className}>
      {
        props.prod_desc_web &&
        <p className={classes.prod_desc_web}>{props.prod_desc_web}</p>
      }
      {
        props.cat1 &&
        <p className={classes.cat1}>{languages[lang!].cat1}: {props.cat1}</p>
      }
      {
        props.cat2 &&
        <p className={classes.cat2}>{languages[lang!].cat2}: {props.cat2}</p>
      }
      {
        props.brand &&
        <p className={classes.brand}>{languages[lang!].brand}: {props.brand}</p>
      }
      {
        props.spec &&
        <p className={classes.spec}>{languages[lang!].spec}: {props.spec}</p>
      }
      {
        props.stor_con &&
        <p className={classes.stor_con}>{languages[lang!].stor_con.name}: {languages[lang!].stor_con[props.stor_con]}</p>
      }
      {
        props.country_name &&
        <p className={classes.country_name}>{languages[lang!].country_name}: {props.country_name}</p>
      }
    </div>
  )
}

ProductDetail.defaultProps = {
  lang: 'en',
}

export default ProductDetail;
