import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { Spinner as BootstrapSpinner, SpinnerProps as BootstrapSpinnerProps } from 'react-bootstrap';

import { Theme } from '../../theme';

const useStyles = makeStyles<Theme, SpinnerProps>((theme) => ({
  loader: {
    color: theme.palette.primary.main,
    width: '100%',
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export interface SpinnerProps extends Omit<BootstrapSpinnerProps, 'animation'> {
}

const Spinner: FC<SpinnerProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  return (
    <div className={classes.loader}>
      <BootstrapSpinner animation='border' />
    </div>
  );
}

Spinner.defaultProps = {
};

export default Spinner;
