import { FC, ReactNode, useCallback, useEffect, useState  }  from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Header from '../views/cms/AnglissView/Header';
import Footer from "../views/cms/AnglissView/Footer";
import axiosInstance from "../utils/axios";
import { API_URL } from "../constants";
import { GeneralSettings } from "../types/generalSettings";
import { getGeneralSettings } from "../graphql/generalSettings";
import { Navigation } from "../types/navigation";
import client from "../utils/apollo";
import Spinner from "../components/common/Spinner";
import useLanguage from '../hooks/useLanguage';

interface MainLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    minHeight: '100vh'
  }
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const { lang } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [generalSettings, setGeneralSettings] = useState<GeneralSettings>({});
  // default main navigation id is 1 based on the plugin
  const [mainNavigation, setMainNavigation] = useState<Navigation>({ id: 1 });
  const loadGeneralSettings = useCallback(async () => {
    const result = await client.query({
      query: getGeneralSettings(lang),
    });
    try {
      setGeneralSettings(result.data.general);
    } catch (error) {
      console.error(error);
    }
  }, [lang]);

  const loadMainMenu = useCallback(async () => {
    const result = await axiosInstance.get(`${API_URL}/navigation/1`);
    setMainNavigation(result.data);
    localStorage.setItem("mainNavigation", JSON.stringify(result.data));
    setLoading(false);
  }, []);

  useEffect(() => {
    loadGeneralSettings();
    loadMainMenu();
  }, [loadGeneralSettings, loadMainMenu]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          {loading && <Spinner />}
          {!loading && <div className={classes.content}>
            <Header generalSettings={generalSettings} mainNavigation={mainNavigation} />
            {children}
            <Footer generalSettings={generalSettings} mainNavigation={mainNavigation} />
          </div>}
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
