import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core';
import marked from 'marked';

import { Theme } from '../theme';
import { UploadFile } from '../types/uploadFile';
import ImageButton, { ImageButtonType } from './common/ImageButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    width: '100%',
    height: '100vh',
    marginBottom: '2rem',
    overflowY: 'scroll',
  },
  row: {},
  col: {},
  text: {},
  button: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));

export interface TextWithButtonProps {
  id: number,
  description?: string,
  button_type?: ImageButtonType,
  button_text?: string,
  button_image?: UploadFile,
  button_image_alignment?: string,
  button_url?: string,
  page_fullwidth?: boolean,
}

/**
 * @deprecated
 */
const TextWithButton: FC<TextWithButtonProps> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.button_url) {
      history.push(props.button_url);
    }
  }

  return (
    <section className={classes.root}>
      <Container className={classes.container} fluid={props.page_fullwidth ?? false}>
        <Row className={classes.row}>
          <Col
            className={classes.col}
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
          >
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: marked(props.description ?? ''),
              }}
            ></div>
          </Col>
        </Row>
        <Row className={classes.row}>
          <Col
            className={classes.col}
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 2, offset: 5 }}
          >
            <ImageButton
              className={classes.button}
              type='button'
              text={props.button_text ?? 'News'}
              onClick={handleClick}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default TextWithButton;
