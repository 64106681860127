import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';

import { Theme } from '../theme';
import { UploadFile } from '../types/uploadFile';

const cssProperties = {
  root: {
    // backgroundColor: theme.palette.background.default,
  },
  container: {},
  row: {},
  col: {},
  map: {
    width: '100%',
    height: '100vh',
  },
};

const useStyles = makeStyles((theme: Theme) => (cssProperties));

export interface GoogleMapProps {
  className?: string,
  id: number,
  api_key: string,
  zoom: number,
  latitude: number,
  longitude: number,
  disable_default_ui?: boolean,
  zoom_control?: boolean,
  map_type_control?: boolean,
  street_view_control?: boolean,
  pan_control?: boolean,
  scale_control?: boolean,
  rotate_control?: boolean,
  fullscreen_control?: boolean,
  draggable?: boolean,
  markers?: GoogleMapMarker[],
  onGoogleMapLoaded?: (map: any, maps: any) => void,
}

export interface GoogleMapMarker {
  name?: string;
  latitude: number;
  longitude: number;
  icon?: UploadFile;
}

/**
 * @deprecated
 */
const GoogleMap: FC<GoogleMapProps> = (props) => {
  const classes = useStyles();
  const mapClasses = props.className ?? classes.map;

  const options = {
    disableDefaultUI: props.disable_default_ui ?? false,
    zoomControl: props.zoom_control ?? true,
    mapTypeControl: props.map_type_control ?? true,
    streetViewControl: props.street_view_control ?? true,
    panControl: props.pan_control ?? true,
    scaleControl: props.scale_control ?? true,
    rotateControl: props.rotate_control ?? true,
    fullscreenControl: props.fullscreen_control ?? true,
    draggable: props.draggable ?? true,
  };

  const handleGoogleMapLoaded = (map: any, maps: any) => {
    props.markers?.forEach((marker) => {
      new maps.Marker({
        position: {
          lat: marker.latitude,
          lng: marker.longitude,
        },
        icon: marker.icon ? marker.icon!.url : undefined,
        map,
      });
    });

    props.onGoogleMapLoaded?.(map, maps);
  }

  return <div className={mapClasses}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: props.api_key }}
            defaultCenter={{lat: props.latitude, lng: props.longitude}}
            defaultZoom={props.zoom}
            options={options}
            onGoogleApiLoaded={({map, maps}) => handleGoogleMapLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals
          />
         </div>
}

export default GoogleMap;
