import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import marked from 'marked';

import { Theme } from '../../theme/index';

const useStyles = makeStyles<Theme, TextProps>((theme) => ({
  text: (props) => ({}),
}));

export interface TextProps {
  className?: string;
  html?: string;
}

const Text: FC<TextProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div
      className={classes.text + ' ' + props.className}
      dangerouslySetInnerHTML={{
        __html: marked(props.html ?? ''),
      }}
    ></div>
  );
}

Text.defaultProps = {
  html: '',
}

export default Text;
