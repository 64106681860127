import { FC } from "react";
import PostView from './PostView';
import BrandDetail from '../../brand/BrandDetail';

/**
 * 这个页面是为了兼容brands设计的,将来或许可以兼容新的设计,或者根据后台返回内容,直接使用PostView
 */

interface SwitchViewProps { }

const SwitchView: FC<SwitchViewProps> = () => {
  const url = new URL(window.location.href);
  const pathParams: any = url.pathname.split('/');
  
  switch(pathParams[1]) {
    case 'brands':
      return <BrandDetail />;
    default:
      return <PostView />
  }
 
};

export default SwitchView;
