import {
  forwardRef,
} from 'react';
import type {
  HTMLProps,
  ReactNode
} from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Theme } from "../theme";

export interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
  font?: string;
}

const useStyles = makeStyles<Theme, PageProps>((theme) => ({
  root: (props) => ({
    minHeight: 500,
  }),
}));

const Page = forwardRef<HTMLDivElement, PageProps>(({
  children,
  title = '',
  font,
  ...rest
}, ref) => {
  font = font?.replaceAll(/[_]/g, ' ');

  const props = {font, title, ...rest};
  const classes = useStyles(props);

  return (
    <div
      ref={ref as any}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classes.root} style={{fontFamily: font}}>
        {children}
      </div>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
