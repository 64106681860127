import React, { FC, ReactNode, useLayoutEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme/index';

const useStyles = makeStyles((theme: Theme) => ({
  image: (props) => ({}),
  noImage: (props) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #BEA26B',
  }),
}));

export interface ImageWithFrameProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  frame_width?: string;
  frame_height?: string;
  image_url?: string;
  image_width?: number;
  image_height?: number;
}

const ImageWithFrame: FC<ImageWithFrameProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  const [frameWidth, setFrameWidth] = useState<string>(props.frame_width ?? ImageWithFrame.defaultProps!.frame_width!);
  const [frameHeight, setFrameHeight] = useState<string>(props.frame_height ?? ImageWithFrame.defaultProps!.frame_height!);
  const [backgroundSize, setBackgroundSize] = useState<string>('100% 100%');

  const imageRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setFrameWidth(props.frame_width!);
    setFrameHeight(props.frame_height!);

    if (imageRef?.current) {
      let refinedHeight;
      if (props.frame_height &&
          (
            props.frame_height?.indexOf('auto') > -1 ||
            props.frame_height?.indexOf('%') > -1
          ) // fix auto and % hidden image problem
         ) {
        refinedHeight = ImageWithFrame.defaultProps?.frame_height!;
      } else if(props.frame_height === 'frame_width') {
        refinedHeight = imageRef.current.offsetWidth ? imageRef.current.offsetWidth + 'px' : ImageWithFrame.defaultProps?.frame_height!;
      } else if (props.frame_height === 'content_width') {
        refinedHeight = props.image_width && props.image_height ?
                        (imageRef.current.offsetWidth / props.image_width * props.image_height) + 'px' :
                        ImageWithFrame.defaultProps!.frame_height!;
      } else {
        refinedHeight = props.frame_height;
      }

      const frameOffsetWidth = imageRef.current.offsetWidth ? imageRef.current.offsetWidth + 'px' : ImageWithFrame.defaultProps?.frame_height!;
      const finalHeight = refinedHeight ?? frameOffsetWidth;
      setFrameHeight(finalHeight);

      const image_width  = props.image_width ?? 0;
      const image_height  = props.image_height ?? 0;

      if (image_width > image_height) { // horizontal image
        setBackgroundSize(`${frameOffsetWidth} auto`);
      } else if (image_width < image_height) { // vertical image
        setBackgroundSize(`auto ${finalHeight}`);
      }
    }
  }, [
    props.frame_width, props.frame_height, props.image_width, props.image_height,
    frameWidth, frameHeight, backgroundSize
  ]);

  return (
    <span>
    {
      props.image_url ? 
      <div
      id={props.id}
      className={classes.image + (props.className ? ' ' + props.className : '')}
      style={{
        backgroundImage: `url(${props.image_url})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize,
        width: frameWidth,
        height: frameHeight,
      }}
      ref={imageRef}
    ></div>
      : ''
    }
    </span>
    // <div
    //   id={props.id}
    //   className={classes.image + (props.className ? ' ' + props.className : '')}
    //   style={{
    //     backgroundImage: `url(${props.image_url})`,
    //     backgroundPosition: 'center center',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize,
    //     width: frameWidth,
    //     height: frameHeight,
    //   }}
    //   ref={imageRef}
    // >
    //   {
    //     props.image_url ?
    //     '' : ''
    //     // <div className={classes.noImage}>No Image</div>
    //   }
    // </div>
  );
}

ImageWithFrame.defaultProps = {
  frame_width: '100%',
  frame_height: 'frame_width',
}

export default ImageWithFrame;
