import React, { FC, useRef, useState } from "react";
import { Card, Container, Row, Col, Overlay, Popover, Button } from "react-bootstrap";
import marked from "marked";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../theme";
import { UploadFile } from "../types/uploadFile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export interface TextWithBackgroundProps {
  id: number;
  description?: string;
  cover?: UploadFile;
  alignment: string;
  color?: string;
  tooltip?: string;
  height?: number;
  width?: number;
  text_offset?: number;
  isSingle: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    border: 0,
    borderRadius: 0,
    fontSize: 'max(12px, calc(0.1em + 1vw))',
  },
  slideTooltip: {
    textAlign: 'center'
  },
  moreIcon: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  darkCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  "@global": {
    "@media (max-width: 800px)": {
      ".text-with-background": {
        height: "auto !important"
      }
    },
    "@media (min-width: 800px)": {
      ".text-with-background": {
        padding: "8rem !important",
      }
    },
  }
}));

export const TextWithBackground: FC<TextWithBackgroundProps> = (props) => {
  const classes = useStyles();
  const ref = useRef(null);
  const [target, setTarget] = useState(null);
  const [show, setShow] = useState(false);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleHide = (event: any) => {
    setShow(!show);
  }

  return (
    <Card className={classes.root}
      style={{
        // minHeight: '840px',
      }}
    >
        {/* {props.height}
        {props.isSingle}
        {props.cover ? 'notnull': 'null'} */}
        <Container fluid={true} 
          style={{
            maxHeight: props.cover ? (props.height ? `${props.height}px` : 'none') : 'none',
          }}
        >
          <Row
            className={props.isSingle ? "text-with-background" : ""}
            style={{
              backgroundImage: `url(${props.cover?.url})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              minHeight: props.cover ? (props.height ? `${props.height}px` : 500) : 0,
              height: props.height ? isNaN(props.height) ? `${props.height}` : `${props.height}px` : 'auto',
              padding: "40px 5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundRepeat: "no-repeat"
            }}
          >
            {/* {props.width}
            {props.isSingle} */}
            {props.color === 'light' && <div className={classes.darkCover} />}
            <Col
              className={props.color ?? ""}
              xs={{ span: 10, offset: 1}}
              lg={{ span: props.isSingle ? (props.width ?? 5) : (props.width ?? 10), offset: props.alignment === "left" ? props.text_offset : (props.isSingle ? 6 : 1) }}
            >
              {props.description && (
                <>
                  <div
                    className={`content-body ${props.alignment === 'center' ? 'text-center' : 'text-left'}`}
                    dangerouslySetInnerHTML={{
                      __html: marked(props.description),
                    }}
                  ></div>
                </>
              )}
              {props.tooltip && (
              <>
                <Button 
                    className={classes.moreIcon}
                    onClick={handleClick} 
                    variant="link">
                  <FontAwesomeIcon 
                    icon={show ? faChevronUp : faChevronDown} />
                </Button>
                
                
                  <Overlay
                  show={show}
                  target={target}
                  placement="bottom"
                  rootClose={true}
                  rootCloseEvent={'click'}
                  onHide={handleHide}
                  container={ref.current}
                  containerPadding={20}
                >
                  <Popover id="popover-contained">
                    <Popover.Content>
                    <div
                      className={`text-left`}
                      dangerouslySetInnerHTML={{
                        __html: marked(props.tooltip),
                      }}
                    ></div>
                    </Popover.Content>
                  </Popover>
                </Overlay>
              </>
              )}
            </Col>
          </Row>
        </Container>
    </Card>
  );
};

TextWithBackground.defaultProps = {
  text_offset: 1,
  isSingle: true
}

export default TextWithBackground;
