import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import ProductCarousel, { ProductCarouselProps } from './Carousel';
import ProductDetail, { ProductDetailProps } from './Detail';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductModalProps>((theme) => ({
  carousel: (props) => ({
    marginTop: '1rem',
    marginBottom: '2rem',
  }),
  detail: (props) => ({}),
}));

export interface ProductModalProps extends ProductCarouselProps, ProductDetailProps {
  lang?: SupportedLanguages;
  onCloseClick?: () => void;
}

const ProductModal: FC<ProductModalProps> = (props) => {
  const classes = useStyles(props);
  const languages = require('./ProductModalLang.json');

  const { lang, ...data } = props;

  return (
    <Modal size="lg" show={props.show === 'Y'} onHide={props.onCloseClick}>
      <Modal.Header closeButton>
        <Modal.Title>{languages[lang!].title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ProductCarousel className={classes.carousel} slides={props.slides} />
        <ProductDetail className={classes.detail} lang={lang} {...data} />
      </Modal.Body>
    </Modal>
  )
}

ProductModal.defaultProps = {
  lang: 'en',
  show: 'Y',
}

export default ProductModal;
