import React, { FC, useLayoutEffect, useState } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';
import ImageWithFrame, { ImageWithFrameProps } from '../../common/ImageWithFrame';

const useStyles = makeStyles<Theme, ProductCarouselProps>((theme) => ({
  slider: (props) => ({}),
  slide: (props) => ({}),
  '@global': {
    '.slick-dots li.slick-active button::before': {
      color: theme.palette.primary.main,
    },
    '.slick-dots li button::before': {
      color: theme.palette.primary.main,
    },
  },
}));

export interface ProductCarouselProps {
  className?: string;
  slides?: ImageWithFrameProps[];
  slide_per_view?: number;
  slide_to_scroll?: number;
  auto_play?: boolean;
  auto_play_speed?: number;
  pause_on_hover?: boolean;
  infinite?: boolean;
  indicators?: boolean;
  nextArrow?: JSX.Element;
  prevArrow?: JSX.Element;
}

const ProductCarousel: FC<ProductCarouselProps> = (props) => {
  const classes = useStyles(props);

  const [slides, setSlides] = useState<ImageWithFrameProps[]>([]);

  useLayoutEffect(() => {
    setSlides(props.slides!);
  }, [props.slides]);

  const settings = {
    slidesToShow: props.slide_per_view,
    slidesToScroll: props.slide_per_view,
    autoplay: props.auto_play,
    autoplaySpeed: props.auto_play_speed,
    pauseOnHover: props.pause_on_hover,
    infinite: props.infinite,
    dots: props.indicators,
    nextArrow: props?.nextArrow,
    prevArrow: props?.prevArrow,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
          slidesToScroll: props.slide_per_view! > 2 && props.slides!.length > 0 ? 2 : 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {
        slides!.length === 0 &&
        <ImageWithFrame className={classes.slider + (props.className ? ' ' + props.className : '')} />
      }
      {
        slides!.length > 0 &&
        <Slider className={classes.slider + (props.className ? ' ' + props.className : '')} {...settings}>
          {
            slides.map((slide: ImageWithFrameProps, i: number) => (
              <div key={i} className={classes.slide}>
                <ImageWithFrame {...slide} />
              </div>
            ))
          }
        </Slider>
      }
    </>
  )
}

ProductCarousel.defaultProps = {
  slides: [],
  slide_per_view: 3,
  auto_play: false,
  auto_play_speed: 4000,
  pause_on_hover: false,
  infinite: false,
  indicators: true,
}

export default ProductCarousel;
