import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import ProductPdfViewer, { ProductTableData } from './ProductPdfViewer';
// import Button from '../../common/Button';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductPdfDownloadModalProps>((theme) => ({
  header: (props) => ({}),
  body: (props) => ({}),
  title: (props) => ({
    color: theme.palette.primary.main,
  }),
}));

export interface ContactInformation {
  name: string;
  company: string;
  title_or_dept: string;
  email: string;
  phone: string;
}

export interface ProductPdfDownloadModalProps {
  lang?: SupportedLanguages;
  show?: boolean;
  products?: ProductTableData[];
  onDownloadClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseClick?: () => void;
}

const ProductPdfDownloadModal: FC<ProductPdfDownloadModalProps> = (props): JSX.Element => {
  const classes = useStyles(props);
  const languages = require('./ProductPdfDownloadModal.json');

  return (
    <Modal size="lg" show={props.show} onHide={props.onCloseClick}>
      <Modal.Header className={classes.header} closeButton>
        <Modal.Title><span className={classes.title}>{languages[props.lang!].title}</span></Modal.Title>
      </Modal.Header>

      <Modal.Body className={classes.body}>
        <ProductPdfViewer products={props.products}/>
      </Modal.Body>

      {
        /* skip download and cancel button
        <Modal.Footer>
          <Button className={classes.button} onClick={props.onCloseClick}>{languages[props.lang!].cancel}</Button>
          <Button className={classes.button} onClick={props.onDownloadClick}>{languages[props.lang!].download}</Button>
        </Modal.Footer>
        */
      }
    </Modal>
  )
}

ProductPdfDownloadModal.defaultProps = {
  lang: 'en',
  show: true,
}

export default ProductPdfDownloadModal;
