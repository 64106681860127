import React, { FC, useState, useLayoutEffect } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../../theme';

const useStyles = makeStyles<Theme, SearchBarProps>((theme: Theme) => ({
  root: {
    marginBottom: '2rem',
    marginLeft: '-1.5vw',
  },
  searchBar: {
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0 0 0 .2rem rgba(190, 162, 107, .25)',
    },
  },
  searchButton: {
    border: 0,
    color: theme.palette.primary.main,
    '&:hover, &:active': {
      backgroundColor: theme.palette.primary.main,
      border: 0,
    },
  },
}));

export type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export type SearchEvent = React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>;

export interface SearchBarProps {
  className?: string;
  searchKeyword?: string;
  onKeywordChange?: (event: React.ChangeEvent<FormControlElement>) => void,
  onSearch?: (event: SearchEvent, searchKeyword: string) => void,
}

const SearchBar: FC<SearchBarProps> = (props) => {
  const classes = useStyles(props);

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch(event.key) {
      case 'Enter':
        props.onSearch?.(event, searchKeyword);
        break;
    }
  }

  const handleButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
    props.onSearch?.(event, searchKeyword);
  }

  useLayoutEffect(() => {
    setSearchKeyword(props.searchKeyword!);
  }, [props.searchKeyword]);

  return (
    <InputGroup className={classes.root + (props.className ? ' ' + props.className : '')}>
      <FormControl
        type='search'
        className={classes.searchBar}
        arial-label='search keyword'
        placeholder='Search'
        value={searchKeyword}
        onChange={(event) => {
          event.preventDefault();
          setSearchKeyword(event.target.value);
          props.onKeywordChange?.(event);
        }}
        onKeyUp={handleKeyUp}
      />
      <Button className={classes.searchButton}
        type='submit'
        variant='outline-secondary'
        onClick={handleButtonClick}
      >
        <FontAwesomeIcon
          icon={faSearch}
        />
      </Button>
    </InputGroup>
  );
}

SearchBar.defaultProps = {
  searchKeyword: '',
}

export default SearchBar;
