import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'quill/dist/quill.core.css';
import './fonts.js';
import { LanguageProvider } from './contexts/LanguageContext';

const history = createBrowserHistory();

const App: FC = () => {
  const theme = createTheme({
    theme: 'LIGHT'
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
          <LanguageProvider>
            <Router history={history}>
                {renderRoutes(routes)}
            </Router>
          </LanguageProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
