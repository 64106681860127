import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "../../../components/Page";
import { Theme } from "../../../theme";
import ContentBuilder from "./ContentBuilder";
import axiosInstance from "../../../utils/axios";
import { API_URL } from "../../../constants";
import Spinner from '../../../components/common/Spinner';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        marginTop: 40,
        marginBottom: 40,
    },
    row: {
        marginTop: 20
    },
    backRow: {
        marginTop: 40,
        textAlign: 'center'
    },
    backBtn: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 40,
        paddingRight: 40,
        color: 'white',
        borderRadius: 0,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.primary.contrastText
        }
    },
    btnText: {
        marginLeft: 30
    },
    '@global': {
        'h1': {
            fontSize: 'max(30px, 3em)',
        },
        'h2': {
            fontSize: 'max(25px, 2.5em)',
        },
        'h3': {
            fontSize: 'max(20px, 2.2em)',
        },
        'h4': {
            fontSize: 'max(18px, 2em)',
        },
        'h5': {
            fontSize: 'max(16px, 1.8em)',
        },
        'h6': {
            fontSize: 'max(14px, 1.5em)',
        },
        '.light': {
        color: theme.palette.common.white
        },
        '.dark': {
        color: theme.palette.common.black
        },
        'a': {
            color: theme.palette.primary.main,
        }
    }
    
}));
interface DetailViewProps {}

const DetailView: FC<DetailViewProps> = () => {
    const classes = useStyles();
    const cachedMainNavigation = localStorage.getItem('mainNavigation');
    const mainNavigation = cachedMainNavigation ? JSON.parse(cachedMainNavigation) : null;
    const url = new URL(window.location.href);
    const [postObject, setPostObject] = useState<any>();
    const [loading, setLoading] = useState(true);

    if (!mainNavigation) {
        window.location.href="/404";
    }
    const pathParams: any = url.pathname.split('/');
    const postId = pathParams[2].replaceAll('/', '');
    let backPathParams: any = [];
    if (pathParams) {
        backPathParams = [...pathParams];
        backPathParams.pop();
    }
    console.log(pathParams, backPathParams)
    const getPostData = useCallback(async () => {
        setLoading(true);
        try {
            const result = await axiosInstance.get(`${API_URL}/posts/${postId}`);
            setPostObject(result.data);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [postId]); 

    useEffect(() => {
        getPostData();
    }, [getPostData])

    return (
        <Page className={classes.root} title="Angliss" font={postObject?.font}>
            {loading && <Spinner />}
            {!loading && <Container>
                <Row>
                    <Col className="ql-editor">
                        <div
                            className={`text-left`}
                            dangerouslySetInnerHTML={{
                            __html: postObject.description}}
                        ></div>
                    </Col>
                </Row>
                
                        { postObject.content.map((content:any, i: number) => {
                            return <Row className={classes.row}>
                                        <Col>
                                            <ContentBuilder key={i} {...content} />
                                        </Col>
                                    </Row>
                        })}
                
                <Row className={classes.backRow}>
                    <Col>
                    <Link className={classes.backBtn} to={backPathParams.join('/')}><FontAwesomeIcon 
                        icon={faLongArrowAltLeft} /> <span className={classes.btnText}>Back</span> </Link>
                    </Col>
                </Row>
            </Container>
            }
        </Page>
    );
};

export default DetailView;
