import React, { FC, PropsWithChildren } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '../../theme';

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  button: (props) => ({
    border: 0,
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover, &:focus, &:active': {
      border: '0 !important',
      boxShadow: '0 0 0 .2rem rgba(190, 162, 107, .25) !important',
      background: 'white !important',
      color: theme.palette.primary.main + '!important',
    },
  }),
}));

export interface ButtonProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: FC<PropsWithChildren<ButtonProps>> = (props): JSX.Element  => {
  const classes = useStyles(props);

  return <BootstrapButton className={classes.button + (props.className ? ' ' + props.className : '')} onClick={props.onClick}>{props.children}</BootstrapButton>
}

Button.defaultProps = {
}

export default Button;
