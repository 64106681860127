import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';

import { Theme } from '../../theme';

const useStyles = makeStyles<Theme, IFrameProps>((theme) => ({
  iframe: (props) => ({
    width: '100%',
    height: '100vh',
  })
}));

export interface IFrameProps {
  id?: string;
  className?: string;
  title?: string;
  url?: string;
  allowFullScreen?: boolean;
  sandbox?: string;
  onClick?: (event: React.MouseEvent<HTMLIFrameElement>) => void;
}

const IFrame = forwardRef<HTMLIFrameElement, IFrameProps>((props, ref) => {
  const classes = useStyles(props);

  return (
    <iframe
      id={props.id}
      className={classes.iframe + ' ' + props.className}
      title={props.title}
      src={props.url}
      allowFullScreen={props.allowFullScreen}
      sandbox={props.sandbox}
      ref={ref}
      onClick={(e) => props.onClick?.(e)}
    >
    </iframe>
  )
})

IFrame.defaultProps = {
  title: '',
  url: '',
  allowFullScreen: false,
}

export default IFrame;
