import { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { Product as ProductDataGridProduct } from './ProductDataGrid';

import { Theme } from '../../../theme';
import { SupportedLanguages } from '../../../types/SupportedLanguages';

const useStyles = makeStyles<Theme, ProductPdfPreviewTableProps>((theme) => ({
  table: (props) => ({
    width: '100%',
  }),
}));

export interface ProductPdfPreviewTableProps {
  lang?: SupportedLanguages;
  products?: ProductDataGridProduct[];
}

const ProductPdfPreviewTable: FC<ProductPdfPreviewTableProps> = (props): JSX.Element  => {
  const classes = useStyles(props);
  const languages = require('./ProductPdfPreviewTable.json');

  const wantedKeys = ['prod_desc_web', 'spec', 'country_name'];
  const filteredProducts = props.products!.map((product) => _.pick(product, wantedKeys));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="products preview">
        <TableHead>
          <TableRow>
            {
              wantedKeys.map((key, index) => <TableCell key={index}>{languages[props.lang!][key]}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredProducts.map((product, index) => 
              <TableRow key={index}>
                {
                  Object.keys(product).map((key, prodIndex) => <TableCell key={prodIndex}>{product[key]}</TableCell>)
                }
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ProductPdfPreviewTable.defaultProps = {
  lang: 'en',
  products: [],
}

export default ProductPdfPreviewTable;
