import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Image } from 'react-bootstrap';
import { ButtonType } from 'react-bootstrap/Button';

import { Theme } from '../../theme';
import { UploadFile } from '../../types/uploadFile';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  image: {},
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
}));

export type ImageButtonType = 'none' | ButtonType;
export type ImagePositionType = 'none' | 'left' | 'right' | 'contain_left' | 'contain_right';

export interface ImageButtonProps {
  className?: string;
  id?: number;
  type?: ImageButtonType;
  text?: string;
  image?: UploadFile | undefined;
  image_position?: ImagePositionType;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ImageButton: FC<ImageButtonProps> = (props) => {
  const classes = useStyles();
  const buttonType = props.type ?? 'none';
  const imageElement = <Image className={classes.image} src={props.image?.url || ''} />;

  return (
    <>
      {
        props.image_position === 'left' && imageElement
      }
      {
        buttonType !== 'none' &&
        <Button type={buttonType} className={classes.button + ' ' + props.className} onClick={props.onClick}>
          {
            props.image_position === 'contain_left' && imageElement
          }
          { props.text ?? 'Submit' }
          {
            props.image_position === 'contain_right' && imageElement
          }
        </Button>
      }
      {
        props.image_position === 'right' && imageElement
      }
    </>
  );
}

export default ImageButton;
